// Will mostly have only UI component imports and placement in this file.

import { useEffect, useState } from "react";
import { Col, Tab, Tabs } from "react-bootstrap";
import { useCommonStateContext } from "../../../hooks/commonStateContext";

import { supportedTokens } from "../../../components/static/SupportedTokens";
import PlButton from "../../../components/buttons/Button";
import Confirm from "../../../components/modals/Confirm";
import CompleteTransaction from "../../../components/modals/CompleteTransaction";
import { PercentageButtons } from "../../../components/buttons/Percentage";
import { SlippageRow } from "../../../components/SlippageRow";
import WithTagSelect from "../../../components/inputFields/WithTagSelect";
import WithTag from "../../../components/inputFields/WithTag";

import {
  addLiquidity,
  approve, approvedTranche,
  calcAddLiquidity,
  calcRemoveLiquidity,
  getApprovedAmount,
  getTokenPrice,
  poolApprove, removeLiquidity,
} from "../../../io/kava";
import configs from "../../../config.json";
import { waitingToast } from "../../../components/toasts/Waiting";
import { successToast } from "../../../components/toasts/Success";
import { handleError } from "../../../components/toasts/Error";
import { useMetaMask } from "metamask-react";
import { ReactComponent as InfoTag } from "../../../assets/images/info_outline.svg";
import { toolTipData } from "../../../components/static/ToolTipData";
import ReactTooltip from "react-tooltip";

const BuySell = ({
                   trancheType,
                   trancheConditions,
                   setTrancheConditions,
                   isSwitchOn,
                   setIsSwitchOn,
                 }) => {

  const [confirmLiquidityModal, setConfirmLiquidityModal] = useState(false); // to show and hide confirmation  modal on buy/sell
  const [amountIn, setAmountIn] = useState(""); //user input the amount to add liquidity
  const [amountOut, setAmountOut] = useState(""); //user get tranche amount according amountIn and selected token
  const [minimumReceived, setMinimumReceived] = useState(""); // user received amount according amountOut and slippage
  const [feeAmount, setFeeAmount] = useState(0);
  const { account } = useMetaMask();
  const { setLoading, setShowConfirmModal, setExplorerURL } = useCommonStateContext();
  const { setUsdValue, showConfirmModal, handleCloseSuccessModal } = useCommonStateContext();

  const handleShowModal = async () => {
    setConfirmLiquidityModal(true);
    let getFrom;
    let getTo;
    if (trancheConditions.selectedTrancheTab === "buy") {
      const fromPrice = await getTokenPrice(trancheConditions.selectedToken.value);
      getFrom = Number(fromPrice) * Number(amountIn);
      getTo = Number(amountOut) * trancheConditions.tranchePrice;
    } else {
      getFrom = Number(amountIn) * trancheConditions.tranchePrice;
      const toPrice = await getTokenPrice(trancheConditions.selectedToken.value);
      getTo = Number(toPrice) * Number(amountOut);
    }
    setUsdValue({
      fromUsdValue: parseFloat(Number(getFrom).toFixed(2)),
      toUsdValue: parseFloat(Number(getTo).toFixed(2)),
    });
  };


  // for get llp value according input asset
  const countAmountOut = async () => {
    try {
      let calLpAmount;
      if (trancheConditions.selectedTrancheTab === "buy") {
        try {
          calLpAmount = await calcAddLiquidity(
            configs.tranches[trancheType],
            trancheConditions.selectedToken.value,
            amountIn,
          );
        } catch (e) {
          console.error("error--", e);
        }

      } else {
        try {
          calLpAmount = await calcRemoveLiquidity(
            configs.tranches[trancheType],
            trancheConditions.selectedToken.value,
            amountIn,
          );
        } catch (e) {
          console.error("error--", e);
        }
      }
      setFeeAmount(Number(calLpAmount.daoFee) / amountIn * 100);
      console.log("fees--", Number(calLpAmount.daoFee) / amountIn * 100);
      console.log("calLpAmount--", calLpAmount);
      if (Number(calLpAmount.lpAmount) !== 0) {
        setAmountOut(Number(calLpAmount.lpAmount));
      } else {
        setAmountOut("");
      }
    } catch (e) {
      setAmountOut("");
      setFeeAmount(0);
    }
  };

  useEffect(() => {
    setAmountOut("");
    setAmountIn("");
    // setTrancheConditions({
    //   ...trancheConditions,
    //   selectedToken: supportedTokens[0],
    //   slippage: slippageOptions[0],
    // });
  }, [trancheConditions.selectedTrancheTab]);

  useEffect(() => {
    if (account !== null) {
      countAmountOut();
    }
  }, [trancheConditions.selectedToken, amountIn, account]);

  //for slippage count according selected marketSlippage and amountOut
  useEffect(() => {
    const slippageCount = Number(amountOut) - (Number(amountOut) * Number(trancheConditions.slippage.value)) / 100;
    console.log("slippageCount--", slippageCount);
    setMinimumReceived(slippageCount > 0 ? slippageCount : "");
  }, [amountOut, trancheConditions.slippage]);

  //for approve amount of selected asset(from) or selected tranche
  const handleTrancheApprove = async () => {
    setLoading(true);
    try {
      waitingToast(
        `Approving ${
          trancheConditions.selectedTrancheTab === "buy"
            ? trancheConditions.selectedToken.value
            : `${trancheType === "senior" ? "Senior" : trancheType === "junior" ? "Junior" : "Mezzanine"} llp`
        }`,
      );

      {
        trancheConditions.selectedTrancheTab === "buy"
          ? await approve(trancheConditions.selectedToken.value, isSwitchOn ? configs.trade.levelMaster : configs.trade.pool)
          : await poolApprove(trancheType, isSwitchOn ? configs.trade.levelMaster : configs.trade.pool);
      }

      successToast(
        `${
          trancheConditions.selectedTrancheTab === "buy"
            ? trancheConditions.selectedToken.value
            : `${trancheType === "senior" ? "Senior" : trancheType === "junior" ? "Junior" : "Mezzanine"} llp`
        } Approved!`,
      );

      const amount = await getApprovedAmount(account, trancheConditions.selectedToken.value, isSwitchOn ? configs.trade.levelMaster : configs.trade.pool);
      const trancheAmt = await approvedTranche(trancheType, account, isSwitchOn ? configs.trade.levelMaster : configs.trade.pool);

      setTrancheConditions({
        ...trancheConditions,
        approvedTokenAmount: Number(amount),
        approvedTrancheAmount: Number(trancheAmt),
      });
    } catch (e) {
      handleError(e);
    }
    setLoading(false);
  };

  //to execute add or remove liquidity
  const handleBuySell = async () => {
    console.log("trancheTypeInBuy---", trancheType);
    setLoading(true);
    try {
      waitingToast(
        trancheConditions.selectedTrancheTab === "buy"
          ? `Selling ${parseFloat(Number(amountIn).toFixed(5))} ${
            trancheConditions.selectedToken.value
          } to get ${parseFloat(Number(amountOut).toFixed(5))} ${
            trancheType === "senior" ? "Senior" : trancheType === "junior" ? "Junior" : "Mezzanine"
          } LLP`
          : `Selling ${parseFloat(Number(amountIn).toFixed(5))} ${
            trancheType === "senior" ? "Senior" : trancheType === "junior" ? "Junior" : "Mezzanine"
          } LLP to get ${parseFloat(Number(amountOut).toFixed(5))} ${trancheConditions.selectedToken.value}`,
      );

      let res;
      if (trancheConditions.selectedTrancheTab === "buy") {
        res = await addLiquidity(
          trancheType,
          trancheConditions.selectedToken.value,
          amountIn,
          minimumReceived,
          account,
          amountOut,
          isSwitchOn,
        );
      } else {
        res = await removeLiquidity(
          trancheType,
          trancheConditions.selectedToken.value,
          amountIn,
          minimumReceived,
          account,
          amountOut,
          isSwitchOn,
        );
      }

      successToast(
        trancheConditions.selectedTrancheTab === "buy"
          ? `Selling ${parseFloat(Number(amountIn).toFixed(5))} ${
            trancheConditions.selectedToken.value
          } to get ${parseFloat(Number(amountOut).toFixed(5))} ${
            trancheType === "senior" ? "Senior" : trancheType === "junior" ? "Junior" : "Mezzanine"
          } LLP`
          : `Selling ${parseFloat(Number(amountIn).toFixed(5))} ${
            trancheType === "senior" ? "Senior" : trancheType === "junior" ? "Junior" : "Mezzanine"
          } LLP to get ${parseFloat(Number(amountOut).toFixed(5))} ${trancheConditions.selectedToken.value}`,
      );

      //This URL needs to be updated according to the Kava Explorer.
      setExplorerURL(`https://goerli.etherscan.io/tx/${res.hash}`);
      setShowConfirmModal(true);
    } catch (e) {
      handleError(e);
    }
    setLoading(false);
  };

  return (
    <>
      <Col className="mt-2">
        <div className="shadowed-box">
          <Tabs
            onSelect={(k) => setTrancheConditions({ ...trancheConditions, selectedTrancheTab: k })}
            activeKey={trancheConditions.selectedTrancheTab}
            id="pool-tab"
            className="mb-3 earn-details-tabs pool_tabs"
          >
            <Tab eventKey="buy" title="BUY">
              <div className="mt-4">
                <div className="d-flex align-items-center gap-3 mb-4">
                  <span
                    className={`switch_text && ${!isSwitchOn && "fw-600"}`}>Buy {trancheType === "senior" ? "Senior" : trancheType === "junior" ? "Junior" : "Mezzanine"} LLP</span>
                  <label className="switch">
                    <input
                      type="checkbox"
                      id="check_id"
                      checked={isSwitchOn}
                      onChange={() => setIsSwitchOn(!isSwitchOn)}
                    />
                    <span className="slider round"></span>
                  </label>
                  <span
                    className={`switch_text && ${isSwitchOn && "fw-600"}`}>Stake {trancheType === "senior" ? "Senior" : trancheType === "junior" ? "Junior" : "Mezzanine"} LLP</span>
                </div>
                <WithTagSelect
                  label={"Amount"}
                  placeholder={"0.0"}
                  value={trancheConditions.selectedToken}
                  inputValue={amountIn}
                  onChange={(e) => setAmountIn(e.target.value)}
                  onSelectChange={(val) => setTrancheConditions({ ...trancheConditions, selectedToken: val })}
                  optionValue={supportedTokens}
                  className={Number(amountIn) > trancheConditions.tokenBalance && "loss"}
                />
                <div className="d-flex justify-content-between align-items-center mt-2">
                  <span className="balance">
                    Balance : {parseFloat(trancheConditions?.tokenBalance?.toFixed(5))}{" "}
                    {trancheConditions.selectedToken?.value}
                  </span>
                  <PercentageButtons balance={trancheConditions.tokenBalance} setAmountIn={setAmountIn} />
                </div>
              </div>
              <div className="mt-4">
                <WithTag
                  label={"Amount"}
                  inputClass={"cursor_not_allowed profit"}
                  placeholder={"0.0"}
                  inputValue={amountOut}
                  tag={
                    <div className="d-flex align-items-center gap-1">
                      <div className="lamp_tag eth_lamp"></div>
                      {trancheType.toUpperCase()} LLP
                    </div>
                  }
                />
              </div>

              <div className="mt-4">
                <SlippageRow allDetails={trancheConditions} setAllDetails={setTrancheConditions} />
                <p className="swap_card_details_p d-flex justify-content-between align-items-center">
                  <span className="d-flex align-items-center gap-1">Minimum Received <InfoTag
                    className="info_icon" data-tip={toolTipData.minimumReceived} /></span>
                  <ReactTooltip backgroundColor="black" className="react_tooltip" arrowColor="black" place="top"
                                type="dark" effect="solid" />
                  <span className="fw-600">
                    {minimumReceived.toString().length > 0
                      ? `${parseFloat(minimumReceived?.toFixed(5))} ${trancheType} LLP`
                      : "-"}
                  </span>
                </p>
                <p className="swap_card_details_p d-flex justify-content-between align-items-center">
                  Weight/Target <span
                  className="fw-600">{trancheConditions.weightValue?.toFixed(2)}%/{trancheConditions.targetValue}%</span>
                </p>
                <p className="swap_card_details_p d-flex justify-content-between align-items-center">
                  Fees <span className="fw-600">{feeAmount > 0 ? `${feeAmount.toFixed(2)}%` : "-"}</span>
                </p>
              </div>

              <div className="mt-4">
                <PlButton
                  disabled={Number(amountIn) === 0 || Number(trancheConditions?.tokenBalance) < Number(amountIn)}
                  label={
                    Number(amountIn) === 0
                      ? "enter an amount"
                      : Number(trancheConditions?.tokenBalance) < Number(amountIn)
                      ? "insufficient funds"
                      : trancheConditions.approvedTokenAmount < Number(amountIn)
                        ? "approve"
                        : `buy ${trancheType} llp`
                  }
                  onClick={() =>
                    trancheConditions.approvedTokenAmount < Number(amountIn)
                      ? handleTrancheApprove()
                      : handleShowModal()
                  }
                />
              </div>
            </Tab>
            <Tab eventKey="sell" title="SELL">
              <div className="mt-4">
                <div className="d-flex align-items-center gap-3 mb-4">
                  <span className={`switch_text && ${!isSwitchOn && "fw-600"}`}>Sell from Wallet</span>
                  <label className="switch">
                    <input
                      type="checkbox"
                      id="check_id"
                      checked={isSwitchOn}
                      onChange={() => setIsSwitchOn(!isSwitchOn)}
                    />
                    <span className="slider round"></span>
                  </label>
                  <span className={`switch_text && ${isSwitchOn && "fw-600"}`}>Withdraw & Sell</span>
                </div>
                <WithTag
                  inputClass={
                    isSwitchOn
                      ? Number(amountIn) > Number(trancheConditions.stakingBalance) && "loss"
                      : Number(amountIn) > Number(trancheConditions.trancheBalance) && "loss"
                  }
                  label={"Amount"}
                  placeholder={"0.0"}
                  inputValue={Number(amountIn) > 0 ? Number(amountIn) : amountIn}
                  onChange={(e) => setAmountIn(e.target.value)}
                  tag={
                    <div className="d-flex align-items-center gap-1">
                      <div className="lamp_tag eth_lamp"></div>
                      {trancheType.toUpperCase()} LLP
                    </div>
                  }
                />
                <div className="d-flex justify-content-between align-items-center mt-2">
                  <span className="balance">
                    {isSwitchOn ? "Staking" : "Wallet"} Balance :{" "}
                    {isSwitchOn
                      ? parseFloat(Number(trancheConditions.stakingBalance).toFixed(5))
                      : parseFloat(Number(trancheConditions.trancheBalance).toFixed(5))}{" "}
                    LLP
                  </span>
                  <PercentageButtons setAmountIn={setAmountIn}
                                     balance={isSwitchOn ? trancheConditions.stakingBalance : trancheConditions.trancheBalance} />
                </div>
              </div>
              <div className="mt-4">
                <WithTagSelect
                  label={"Receive"}
                  placeholder={"0.0"}
                  value={trancheConditions.selectedToken}
                  inputValue={amountOut}
                  onSelectChange={(val) => setTrancheConditions({ ...trancheConditions, selectedToken: val })}
                  optionValue={supportedTokens}
                  className={`cursor_not_allowed ${Number(amountOut) > Number(trancheConditions.tokenFund) ? "loss" : "profit"}`}
                />
              </div>

              <div className="mt-4">
                <SlippageRow allDetails={trancheConditions} setAllDetails={setTrancheConditions} />
                <p className="swap_card_details_p d-flex justify-content-between align-items-center mb-3">
                   <span className="d-flex align-items-center gap-1">Minimum Received <InfoTag
                     className="info_icon" data-tip={toolTipData.minimumReceived} /></span>
                  <ReactTooltip backgroundColor="black" className="react_tooltip" arrowColor="black" place="top"
                                type="dark" effect="solid" />
                  <span className="fw-600">
                    {minimumReceived.toString().length > 0
                      ? `${parseFloat(minimumReceived?.toFixed(5))} ${trancheConditions.selectedToken.value}`
                      : "-"}
                  </span>
                </p>
                <p className="swap_card_details_p d-flex justify-content-between align-items-center mb-3">
                  Available Fund{" "}
                  <span className="fw-600">
                    {parseFloat(trancheConditions.tokenFund?.toFixed(5))} {trancheConditions.selectedToken.value}
                  </span>
                </p>
                <p className="swap_card_details_p d-flex justify-content-between align-items-center mb-3">
                  Weight/Target <span
                  className="fw-600">{trancheConditions.weightValue?.toFixed(2)}%/{trancheConditions.targetValue}%</span>
                </p>
                <p className="swap_card_details_p d-flex justify-content-between align-items-center mb-3">
                  Fees <span className="fw-600">{feeAmount > 0 ? `${feeAmount.toFixed(2)}%` : "-"}</span>
                </p>
              </div>

              <div className="mt-4">
                <PlButton
                  disabled={
                    Number(amountIn) === 0 ||
                    Number(amountOut) <= 0 ||
                    Number(amountOut) > Number(trancheConditions.tokenFund) ||
                    (isSwitchOn ? Number(trancheConditions.stakingBalance) : Number(trancheConditions?.trancheBalance)) <
                    Number(amountIn)
                  }
                  label={
                    Number(amountIn) === 0
                      ? "enter an amount"
                      :  (Number(amountOut) <= 0 || Number(amountOut) > Number(trancheConditions.tokenFund))
                      ? "insufficient liquidity"
                      : (isSwitchOn ? Number(trancheConditions.stakingBalance) : Number(trancheConditions?.trancheBalance)) <
                      Number(amountIn)
                        ? "insufficient funds"
                        : trancheConditions.approvedTrancheAmount < Number(amountIn) && !isSwitchOn
                          ? "approve"
                          : `sell ${trancheType} llp`
                  }
                  onClick={() =>
                    trancheConditions.approvedTrancheAmount < Number(amountIn) && !isSwitchOn
                      ? handleTrancheApprove()
                      : handleShowModal()
                  }
                />
              </div>
            </Tab>
          </Tabs>
        </div>
      </Col>

      {confirmLiquidityModal && (
        <Confirm
          title={`CONFIRM ${trancheConditions.selectedTrancheTab.toUpperCase()} LIQUIDITY`}
          show={confirmLiquidityModal}
          trancheType={trancheType}
          onHide={() => setConfirmLiquidityModal(false)}
          modalDetails={{ ...trancheConditions, trancheFee: feeAmount }}
          amountIn={amountIn}
          amountOut={amountOut}
          minimumReceived={minimumReceived}
          handleBuySell={handleBuySell}
        />
      )}

      {showConfirmModal && (
        <CompleteTransaction
          show={showConfirmModal}
          onHide={handleCloseSuccessModal}
          trancheType={trancheType}
          trancheConditions={trancheConditions}
          amountIn={amountIn}
          amountOut={amountOut}
        />
      )}
    </>
  );
};

export default BuySell;
