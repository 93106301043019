import React, { useEffect, useState } from "react";
import { Area, AreaChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis, ReferenceLine } from "recharts";

import rotationIcon from "../../../assets/images/rotation-icon.svg";
import downIcon from "../../../assets/images/arrow_drop_down_red.svg";
import upIcon from "../../../assets/images/arrow_drop_down_green.svg";
import loader from "../../../assets/images/loader.gif";

import { tokenImages } from "../../../components/static/SupportedTokens";
import configs from "../../../config.json";

import { useLazyQuery } from "@apollo/client";
import { GET_CHART_PRICE } from "../../../io/subgraph";
import { timeConditions } from "../../../components/static/TimeOptions";
import { ethers } from "ethers";

const Chart = ({ swapConditions }) => {
  const [chartData, setChartData] = useState([]);
  const [diffCount, setDiffCount] = useState();
  const [selectedTime, setSelectedTime] = useState("24H");
  const [isDataFetching, setIsDataFetching] = useState(false);
  const [takeQuery] = useLazyQuery(GET_CHART_PRICE);

  useEffect(() => {
    getPrices();
  }, [swapConditions.selectedTo, swapConditions.selectedFrom, selectedTime]);

  const getPrices = async () => {
    setIsDataFetching(true);

    const currentTime = Date.now() / 1000;
    const { data } = await takeQuery({
      variables: {
        start: Math.round(currentTime - timeConditions[selectedTime].timeGap),
        end: Math.round(currentTime),
        period: timeConditions[selectedTime].periodName,
        from: configs.tokens[swapConditions.selectedFrom.value],
        to: configs.tokens[swapConditions.selectedTo.value],
      },
      context: { clientName: "analytics" },
    });

    const getAxisData = [];

    const getXAxis = (time) => {
      var u = new Date(time * 1000);
      let hour = u.getHours().toString().padStart(2, "0");
      let minutes = u.getMinutes().toString().padStart(2, "0");
      let day = u.getDate().toString().padStart(2, "0");
      let monthDigit = ("0" + (u.getMonth() + 1)).slice(-2);
      const month = u.toLocaleString("default", { month: "short" });
      let year = u.getFullYear();
      return {
        time: `${hour}:${minutes}`,
        monthOnly: month + ` ${day}`,
        withYear: month + ` ${day}` + ` ${year}`,
        dayMonth: `${day}/${monthDigit}`,
      };
    };
    console.log('step---1');
    for (let i = 0; i < data.tokenFrom.length; i++) {
      getAxisData.push({
        ...getXAxis(data.tokenFrom[i].timestamp),
        uv: (ethers.utils.formatUnits(data.tokenFrom[i].value, 12)) /
          (ethers.utils.formatUnits(data.tokenTo[i].value, 12)),
      });
    }
    console.log('step---2');
    // uv: configs.tokens.USDT.toLowerCase() === data.tokenFrom[i] ? Number(data.tokenFrom[i].value) / Number(data.tokenTo[i].value),
    if(getAxisData?.length > 0){
      const diff =
        getAxisData[0].uv > getAxisData[getAxisData.length - 1].uv
          ? getAxisData[0].uv - getAxisData[getAxisData.length - 1].uv
          : getAxisData[getAxisData.length - 1].uv - getAxisData[0].uv;
      const percentageCount = (diff / getAxisData[0].uv) * 100;
      const isIncrease = getAxisData[getAxisData.length - 1].uv > getAxisData[0].uv;
      console.log('step---3');
      setDiffCount({ diffAmount: diff, percentageCount, diffType: isIncrease ? "+" : "-" });
      setChartData(getAxisData);
    }else {
      setDiffCount()
      setChartData([])
    }

    setIsDataFetching(false);
  };

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip">
          <p className="label mb-1">
            {selectedTime === "24H" || selectedTime === "1W"
              ? `${payload[0].payload.monthOnly}, ${payload[0].payload.time}`
              : payload[0].payload.withYear}
          </p>
          <p className="desc mb-0">
            {swapConditions.selectedFrom.value}/{swapConditions.selectedTo.value} :{" "}
            {parseFloat(payload[0].value.toFixed(Number(payload[0].value.toString().split(".")[0]) <= 99 ? 5 : 2))}{" "}
          </p>
        </div>
      );
    }

    return null;
  };

  const CustomLabel = (props) => {
    return (
      <g>
        <rect
          x={725}
          y={props.viewBox.y - 10}
          style={{ borderRadius: "10px" }}
          fill="#FF6600"
          width="70"
          height="20"
        ></rect>
        <text x={725} y={props.viewBox.y - 10} fill="#fff" dy="15" dx="2" font-weight="600" font-size="14">
          {parseFloat(
            chartData[chartData.length - 1]?.uv.toFixed(
              Number(chartData[chartData.length - 1]?.uv.toString().split(".")[0]) <= 99 ? 5 : 2,
            ),
          )}
        </text>
      </g>
    );
  };

  return (
    <div className="shadowed-box">
      <div className="chart-wrapper">
        <div className="d-flex justify-content-between align-items-center mb-2">
          <div className="chart-title d-flex align-items-center">
            <span className="me-3 position-relative d-flex align-items-center justify-content-center">
              <img src={tokenImages[swapConditions.selectedFrom.value].image} height={22} width={22} />
              <img
                className="bitcoin_icon position-absolute"
                height={22}
                width={22}
                src={tokenImages[swapConditions.selectedTo.value].image}
              />
            </span>
            <h2 className="mb-0">
              {swapConditions.selectedFrom.value}/{swapConditions.selectedTo.value}
            </h2>
            <span className="ms-2">
              <img src={rotationIcon} height={14} width={18} />
            </span>
          </div>
          <div className="d-flex align-items-center justify-content-center gap-2">
            <div
              className={`radio_options ${selectedTime === "24H" && "active"}`}
              onClick={() => setSelectedTime("24H")}
            >
              24H
            </div>
            <div className={`radio_options ${selectedTime === "1W" && "active"}`} onClick={() => setSelectedTime("1W")}>
              1W
            </div>
            <div className={`radio_options ${selectedTime === "1M" && "active"}`} onClick={() => setSelectedTime("1M")}>
              1M
            </div>
            <div className={`radio_options ${selectedTime === "1Y" && "active"}`} onClick={() => setSelectedTime("1Y")}>
              1Y
            </div>
          </div>
        </div>
        {isDataFetching ? (
          <div className="d-flex align-items-center justify-content-center w-100 swap_chart_loader">
            <img className="loading_img" src={loader} />
          </div>
        ) :chartData.length === 0 ?(
          <div className="d-flex align-items-center justify-content-center w-100 swap_chart_loader">
            Data not available.
          </div>
          ):(
          <>
            <div className={`d-flex chart-content-price ${diffCount?.diffType === "+" ? "plus" : "minus"}`}>
              <p>
                1 {swapConditions.selectedFrom.value} = {swapConditions.price} {swapConditions.selectedTo.value}
              </p>
              <span className="ms-2 mt-1">
                {diffCount?.diffType}
                {parseFloat(diffCount?.diffAmount.toFixed(5))}
              </span>
              <span className="ms-2 mt-1">
                <img src={diffCount?.diffType === "+" ? upIcon : downIcon} />
              </span>
              <span className="ms-1 mt-1">{diffCount?.percentageCount?.toFixed(2)}%</span>
            </div>
            <ResponsiveContainer width="100%" height="85%">
              <AreaChart width={730} height={250} data={chartData} margin={{ top: 10, right: 20, left: 22, bottom: 0 }}>
                <defs>
                  <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="5%" stopColor="#FFD000" stopOpacity={0.8} />
                    <stop offset="95%" stopColor="#FFD000" stopOpacity={0} />
                  </linearGradient>
                </defs>
                <YAxis
                  strokeOpacity={0.2}
                  orientation="right"
                  domain={[(dataMin) => dataMin * 0.96, (dataMax) => dataMax * 1.04]}
                  tickFormatter={(value) =>
                    parseFloat(value.toFixed(Number(value.toString().split(".")[0]) <= 99 ? 5 : 2))
                  }
                />
                <XAxis
                  strokeOpacity={0.2}
                  dataKey={`${selectedTime === "24H" ? "time" : "dayMonth"}`}
                  interval={selectedTime === "1W" ? 5 : 3}
                />
                <ReferenceLine
                  y={chartData[chartData.length - 1]?.uv}
                  stroke="#FF6600"
                  strokeDasharray="3 3"
                  strokeWidth={2}
                  label={CustomLabel}
                  alwaysShow={true}
                />
                <CartesianGrid opacity={0.2} />
                <Tooltip content={<CustomTooltip />} />
                <Area type="monotone" dataKey="uv" stroke="#FFD000" fillOpacity={1} fill="url(#colorUv)" />
              </AreaChart>
            </ResponsiveContainer>
          </>
        )}
      </div>
    </div>
  );
};
export default Chart;
