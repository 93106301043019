import Modal from "react-bootstrap/Modal";

import closeIcon from "../../assets/images/modal-close-icon.svg";
import arrow from "../../assets/images/launch_redirect.svg";
import confirm from "../../assets/images/confirm_tickmark.svg";
import { useCommonStateContext } from "../../hooks/commonStateContext";

const CompleteTransaction = (props) => {
  const {
    show,
    onHide,
    selectedOrder,
    swapConditions,
    trancheConditions,
    tradeConditions,
    amountOut,
    amountIn,
    trancheType,
    successMessage,
    orderMessage,
  } = props;
  const { explorerURL, usdValue } = useCommonStateContext();
  console.log("tradeConditions-", tradeConditions);
  return (
    <Modal {...props} size="lg" centered dialogClassName="success-modal" show={show}>
      <Modal.Body>
        <div className="close-icon" onClick={onHide}>
          <img src={closeIcon} />
        </div>

        <div className="confirm_modal mb-3">
          <h3 className="d-flex align-items-center justify-content-center gap-1">
            Transaction Completed!{" "}
            <img className="pointer-cursor" onClick={() => window.open(explorerURL, "_blank")} src={arrow} />
          </h3>
        </div>
        <div className="d-flex my-4 align-items-center justify-content-center">
          <img src={confirm} />
        </div>
        {trancheType ? (
          <p className="modal_text">
            Selling{" "}
            <span>
              {parseFloat(Number(amountIn).toFixed(5))}{" "}
              {trancheConditions.selectedTrancheTab === "buy"
                ? trancheConditions.selectedToken?.value
                : `${trancheType === "senior" ? "Senior" : trancheType === "junior" ? "Junior" : "Mezzanine"} LLP`}{" "}
            </span>{" "}
            to get{" "}
            <span>
              {parseFloat(Number(amountOut).toFixed(5))}{" "}
              {trancheConditions.selectedTrancheTab === "sell"
                ? trancheConditions.selectedToken?.value
                : `${trancheType === "senior" ? "Senior" : trancheType === "junior" ? "Junior" : "Mezzanine"} LLP`}
            </span>
          </p>
        ) : selectedOrder ? (
          <p className="modal_text">
            Canceled order to swap{" "}
            <span>
              {parseFloat(Number(selectedOrder.priceIn).toFixed(5))} {selectedOrder.assetIn}{" "}
            </span>{" "}
            for{" "}
            <span>
              {parseFloat(Number(selectedOrder.priceOut).toFixed(5))} {selectedOrder?.assetOut}
            </span>
          </p>
        ) : swapConditions ? (
          <p className="modal_text">
            {swapConditions.selectedSwapTab === "market" ? "Swapped" : "Order created to swap"}{" "}
            <span>
              {parseFloat(Number(amountIn).toFixed(5))} {swapConditions.selectedFrom?.value}{" "}
            </span>{" "}
            for{" "}
            <span>
              {parseFloat(Number(amountOut).toFixed(5))} {swapConditions.selectedTo?.value}
            </span>
          </p>
        ) : orderMessage?.length > 0 ? (
          <p className="modal_text">{orderMessage} </p>
        ) : tradeConditions ? (
          <p className="modal_text">
            Requested to increase {tradeConditions.selectedTo.value} {tradeConditions.selectedTradeTab} by{" "}
            <span>${usdValue.toUsdValue}</span>
          </p>
        ) : successMessage?.length > 0 ? (
          <p className="modal_text">{successMessage} </p>
        ) : null}
      </Modal.Body>
    </Modal>
  );
};

export default CompleteTransaction;
