import { Col } from "react-bootstrap";

import PlButton from "../../../components/buttons/Button";
import WithTag from "../../../components/inputFields/WithTag";

const Claim = () => {
  return (
    <>
      <Col className="mt-2">
        <div className="shadowed-box card-main-content">
          <div className="card-title mb-3">
            <h3>CLAIM</h3>
          </div>
          <div className="mt-4">
            <WithTag
              label={"Amount"}
              inputClass={"cursor_not_allowed"}
              placeholder={"0.0"}
              tag={
                <div className="d-flex align-items-center gap-1">
                  <div className="lamp_tag eth_lamp"></div>LAMP
                </div>
              }
            />
          </div>

          <div className="modal-confirm-btn mt-4">
            <PlButton label={"Claim"} />
          </div>
        </div>
      </Col>
    </>
  );
};

export default Claim;
