import { ReactComponent as InfoTag } from "../../../assets/images/info_outline.svg";

import { supportedTokens, tokenImages } from "../../../components/static/SupportedTokens";
import WithTagSelect from "../../../components/inputFields/WithTagSelect";
import { toolTipData } from "../../../components/static/ToolTipData";
import SelectField from "../../../components/inputFields/SelectField";
import { orderTypes } from "../../../components/static/OrderTypes";
import DefaultInput from "../../../components/inputFields/DefaultInput";

export const AllDataRows = ({ tradeConditions, liquidationPrice, swapFee, leverage,orderType, collateralValue }) => {
  return (
    <div className="mt-4">
      <p className="swap_card_details_p d-flex justify-content-between align-items-center">
        Collateral Asset{" "}
        <span className="fw-600 d-flex align-items-center gap-1 justify-content-center">
          <img
            src={
              tokenImages[tradeConditions.selectedTradeTab === "long" ? tradeConditions.selectedTo.value : "USDT"].image
            }
            height={14}
            width={14}
          />
          {tradeConditions.selectedTradeTab === "long" ? tradeConditions.selectedTo.value : "USDT"}
        </span>
      </p>
      <p className="swap_card_details_p d-flex justify-content-between align-items-center">
        Collateral Value{" "}
        <span className="fw-600">
          {" "}
          {collateralValue > 0 ? `$${parseFloat(collateralValue?.toFixed(2))} ` : "-"}
        </span>
      </p>
      <p className="swap_card_details_p d-flex justify-content-between align-items-center">
        Leverage<span className="fw-600">{leverage}x</span>
      </p>
      <p className="swap_card_details_p d-flex justify-content-between align-items-center">
        Entry Price{" "}
        <span className="fw-600">
            ${orderType.value === "marketOrder"
          ? parseFloat(Number(tradeConditions.entryPrice)?.toFixed(5))
          : parseFloat(Number(tradeConditions.newEntryPrice)?.toFixed(5))}
        </span>
      </p>
      <p className="swap_card_details_p d-flex justify-content-between align-items-center">
        Liquidation Price{" "}
        <span className="fw-600">
          {" "}
          {liquidationPrice > 0 ? `$${parseFloat(liquidationPrice?.toFixed(2))} ` : "-"}
        </span>
      </p>
      {tradeConditions.selectedFrom !== tradeConditions.selectedTo && (
        <p className="swap_card_details_p d-flex justify-content-between align-items-center">
          Swap Fee {" "}
          <span className="fw-600">{swapFee > 0 ? `${swapFee.toFixed(2)}%` : "-"}</span>
        </p>
      )}
      <p className="market_info">Market Info</p>
      <p className="swap_card_details_p d-flex justify-content-between align-items-center">
        Borrow Fee<span className="fw-600">{parseFloat(tradeConditions.borrowFeeRate?.toFixed(6))}% per hour</span>
      </p>
      <p className="swap_card_details_p d-flex justify-content-between align-items-center mb-0">
        Available Liquidity{" "}
        <span className="fw-600">
          {parseFloat(Number(tradeConditions?.availableLiquidity)?.toFixed(5))} {tradeConditions.selectedTradeTab === "long" ?
          tradeConditions.selectedTo.value : "USDT"}
        </span>
      </p>
    </div>
  );
};

export const OrderPrice = ({ orderType, setOrderType, setTradeConditions, tradeConditions }) => {
  return (
    <>
      <div className="mt-4">
        <SelectField
          label={<span className="d-flex align-items-center gap-1">Order Type<InfoTag
            className="info_icon" data-tip={toolTipData.orderType} /></span>}
          placeholder={"0.0"}
          value={orderType}
          onSelectChange={(val) => setOrderType(val)}
          optionValue={orderTypes}
        />

      </div>
      <div className="mt-4">
        <DefaultInput
          label={<span className="d-flex align-items-center gap-1">Price<InfoTag
            className="info_icon" data-tip={toolTipData.orderPrice} /></span>}
          placeholder={`${orderType.value === "marketOrder" ? "Market Price" : "0.0"}`}
          inputValue={orderType.value === "limitOrder" ? tradeConditions.newEntryPrice : ""}
          onChange={(e) =>
            setTradeConditions({
              ...tradeConditions,
              newEntryPrice: e.target.value.length > 0 ? e.target.value : tradeConditions.entryPrice,
            })
          }
          disabled={orderType.value === "marketOrder"}
        />
      </div>
    </>
  );
};

export const LeverageComp = ({ leverage, setLeverage, tradeConditions }) => {
  return (
    <div className="mt-4">
      <div className="d-flex justify-content-between">
        <label className="range_span" htmlFor="tempB"><span className="d-flex align-items-center gap-1">Leverage<InfoTag
          className="info_icon" data-tip={toolTipData.leverage} /></span>
        </label>
        <span className="range_data">{leverage}x</span>
      </div>
      <input
        type="range"
        onChange={(e) => setLeverage(e.target.value)}
        className="form-range"
        min="2"
        max={tradeConditions.maxLeverage}
        list="values"
        value={leverage}
      />
    </div>
  );
};

export const PositionSize = ({ tradeConditions, setTradeConditions, amountOut }) => {
  const rmStableTokens = [];
  supportedTokens.map((token) => {
    if (token.value !== "USDT") {
      rmStableTokens.push(token);
    }
  });

  return (
    <div className="mt-4">
      <WithTagSelect
        label={<span className="d-flex align-items-center gap-1">Position Size<InfoTag
          className="info_icon" data-tip={toolTipData.positionSize} /></span>
        }
        className={`cursor_not_allowed ${Number(tradeConditions?.availableLiquidity) < Number(amountOut) ? "loss" : "profit"}`}
        placeholder={"0.0"}
        inputValue={amountOut}
        value={tradeConditions.selectedTo}
        onSelectChange={(val) => setTradeConditions({ ...tradeConditions, selectedTo: val })}
        optionValue={rmStableTokens}
      />
    </div>
  );
};

export const AmountDetails = ({ amountIn, setAmountIn, setTradeConditions, tradeConditions }) => {
  return (
    <div className="mt-4">
      <WithTagSelect
        label={<span className="d-flex align-items-center gap-1">Pay<InfoTag
          className="info_icon" data-tip={toolTipData.orderPay} /></span>
        }
        className={Number(amountIn) > tradeConditions.tokenBalance && "loss"}
        placeholder={"0.0"}
        inputValue={amountIn}
        onChange={(e) => setAmountIn(e.target.value)}
        value={tradeConditions.selectedFrom}
        onSelectChange={(val) => setTradeConditions({ ...tradeConditions, selectedFrom: val })}
        optionValue={supportedTokens}
      />
    </div>
  );
};
