import { Col } from "react-bootstrap";

import PlButton from "../../../components/buttons/Button";
import WithTag from "../../../components/inputFields/WithTag";
import { useState } from "react";
import { PercentageButtons } from "../../../components/buttons/Percentage";

const Withdraw = ({ kavaPoolDetails, setKavaPoolDetails }) => {
  const [withdrawAmount, setWithdrawAmount] = useState("");

  return (
    <>
      <Col className="mt-2">
        <div className="shadowed-box card-main-content">
          <div className="card-title mb-3">
            <h3>WITHDRAW</h3>
          </div>
          <div className="mt-4">
            <WithTag
              label={"Amount"}
              placeholder={"0.0"}
              tag={
                <div className="d-flex align-items-center gap-1">
                  <div className="lamp_tag eth_lamp"></div>
                  LAMP
                </div>
              }
            />
            <div className="d-flex justify-content-between align-items-center mt-2">
              <span className="balance">Balance : {Number(kavaPoolDetails.withdrawalBalance)} LAMP/ETH</span>
              <PercentageButtons setAmountIn={setWithdrawAmount} balance={kavaPoolDetails.withdrawalBalance} />
            </div>
          </div>

          <div className="deposite-button mt-4">
            <PlButton
              disabled={Number(withdrawAmount) <= 0 || Number(withdrawAmount) < Number(kavaPoolDetails.withdrawalBalance)}
              label={ "WITHDRAW"} />
          </div>

          <div className="deposite-button mt-4">
            <PlButton label={"Remove Liquidity"} className="transparent" />
          </div>
        </div>
      </Col>
    </>
  );
};

export default Withdraw;
