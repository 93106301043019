import Modal from "react-bootstrap/Modal";
import { useCommonStateContext } from "../../hooks/commonStateContext";

import closeIcon from "../../assets/images/modal-close-icon.svg";
import DownArrow from "../../assets/images/down-circle-arrow.svg";
import arrow from "../../assets/images/arrow-forward-yellow.svg";

import PlButton from "../buttons/Button";
import WithTag from "../inputFields/WithTag";
import { tokenImages } from "../static/SupportedTokens";
import { useEffect } from "react";

const Confirm = (props) => {
  const {
    show,
    onHide,
    modalDetails,
    amountIn,
    amountOut,
    minimumReceived,
    title,
    data,
    orderType,
    selectedOrder,
    setShowModal,
    trancheType,
    handleCancelOrder,
    handleSwap,
    positionFeeValue,
    handleBuySell,
    handleTrade,
  } = props;

  let triggerPrice;
  const { usdValue } = useCommonStateContext();
  console.log("modalDetails---", modalDetails);

  if (data) {
    triggerPrice = orderType.value === "marketOrder"
      ? modalDetails.selectedTradeTab === "long"
        ? parseFloat(
          (
            Number(modalDetails.entryPrice) +
            (Number(modalDetails.entryPrice) * Number(modalDetails.slippage.value)) / 100
          )?.toFixed(3),
        )
        : parseFloat(
          (
            Number(modalDetails.entryPrice) -
            (Number(modalDetails.entryPrice) * Number(modalDetails.slippage.value)) / 100
          )?.toFixed(3),
        )
      : parseFloat(Number(modalDetails.newEntryPrice)?.toFixed(3));
  }

  return (
    <Modal {...props} size="lg" centered dialogClassName="my-modal" show={show}>
      <Modal.Body>
        <div className="close-icon" onClick={onHide}>
          <img src={closeIcon} />
        </div>

        <div className="modal-title mb-3">
          {title ? (
            <h3> {title}</h3>
          ) : (
            <h3>
              {" "}
              Swap {modalDetails.selectedFrom?.value} <img src={arrow} /> {modalDetails.selectedTo?.value}
            </h3>
          )}
        </div>
        <div className="modal-body-content">
          <div className="mt-4">
            <WithTag
              inputClass={"cursor_not_allowed"}
              placeholder={"0.0"}
              inputValue={selectedOrder ? selectedOrder.priceIn : amountIn}
              tag={
                trancheType && modalDetails.selectedTrancheTab === "sell" ? (
                  <div className="d-flex align-items-center gap-1">
                    <div className="lamp_tag eth_lamp"></div>
                    {trancheType.toUpperCase()} LLP
                  </div>
                ) : (
                  <div className="d-flex align-items-center gap-1">
                    <img
                      src={
                        trancheType && modalDetails.selectedTrancheTab === "buy"
                          ? tokenImages[modalDetails.selectedToken?.value]?.image
                          : selectedOrder
                          ? tokenImages[selectedOrder.assetIn]?.image
                          : tokenImages[modalDetails.selectedFrom?.value]?.image
                      }
                      height={12}
                      width={12}
                    />{" "}
                    {trancheType && modalDetails.selectedTrancheTab === "buy"
                      ? modalDetails.selectedToken?.value
                      : selectedOrder
                        ? selectedOrder.assetIn
                        : modalDetails.selectedFrom?.value}
                  </div>
                )
              }
              subValue={`~ $${usdValue?.fromUsdValue}`}
            />
          </div>
          <div className="my-2 d-flex justify-content-center">
            <img src={DownArrow} />
          </div>
          <div>
            <WithTag
              inputClass={"cursor_not_allowed"}
              placeholder={"0.0"}
              inputValue={
                selectedOrder
                  ? selectedOrder.priceOut
                  : amountOut
              }
              tag={
                trancheType && modalDetails.selectedTrancheTab === "buy" ? (
                  <div className="d-flex align-items-center gap-1">
                    <div className="lamp_tag eth_lamp"></div>
                    {trancheType.toUpperCase()} LLP
                  </div>
                ) : (
                  <div className="d-flex align-items-center gap-1">
                    <img
                      src={
                        trancheType && modalDetails.selectedTrancheTab === "sell"
                          ? tokenImages[modalDetails.selectedToken?.value]?.image
                          : selectedOrder
                          ? tokenImages[selectedOrder.assetOut]?.image
                          : tokenImages[modalDetails.selectedTo?.value]?.image
                      }
                      height={12}
                      width={12}
                    />{" "}
                    {trancheType && modalDetails.selectedTrancheTab === "sell"
                      ? modalDetails.selectedToken?.value
                      : selectedOrder
                        ? selectedOrder.assetOut
                        : modalDetails.selectedTo?.value}
                  </div>
                )
              }
              subValue={`~ $${usdValue?.toUsdValue}`}
            />
          </div>
          <div className="mt-3 d-flex justify-content-between">
            <span>{trancheType ? "Fee" : data ? "Position Fee" : selectedOrder ? "Trigger Condition" : "Price"}</span>
            <span className="fw-600">
              {trancheType
                ? `${modalDetails.trancheFee}%`
                : data
                  ? `$${parseFloat(positionFeeValue.toFixed(2))}`
                  : selectedOrder
                    ? `1 ${selectedOrder?.assetIn} ${
                      selectedOrder.priceTriggered >= selectedOrder.marketPrice ? "≥" : "≤"
                    } ${parseFloat(Number(selectedOrder.priceTriggered).toFixed(4))} ${selectedOrder?.assetOut}`
                    : `1 ${modalDetails.selectedFrom?.value} = ${
                      modalDetails.selectedSwapTab === "market"
                        ? parseFloat(modalDetails.price?.toFixed(5))
                        : parseFloat(Number(modalDetails.swapLimitPrice).toFixed(5))
                    } ${modalDetails.selectedTo?.value}`}{" "}
            </span>
          </div>
          <div className="mt-3 d-flex justify-content-between">
            <span>
              {trancheType ? "Slippage" : data ? "Execution Fee" : selectedOrder ? "Market Price" : "Minimum Received"}
            </span>
            <span className="fw-600">
              {data
                ? `${modalDetails.executionFee} KAVA`
                : trancheType
                  ? modalDetails.slippage.label
                  : selectedOrder
                    ? `1 ${selectedOrder?.assetIn} = ${parseFloat(Number(selectedOrder.marketPrice).toFixed(4))} ${
                      selectedOrder?.assetOut
                    }`
                    : minimumReceived > 0
                      ? `${parseFloat(minimumReceived?.toFixed(5))
                      } ${modalDetails.selectedTo?.value}`
                      : "-"}
            </span>
          </div>
          <div className={`mt-3 ${selectedOrder ? "d-none" : "d-flex"} justify-content-between`}>
            <span>{trancheType ? "Minimum Received" : data ? "Trigger Condition" : "Swap Fee"}</span>
            <span className="fw-600" style={data && { color: "#FFD000" }}>
              {trancheType
                // ? 0
                ? parseFloat(minimumReceived?.toFixed(5))
                : data
                  ? `Mark Price ${modalDetails.selectedTradeTab === "long" ? "≤" : "≥"} $${triggerPrice}`
                  : `${modalDetails?.swapFee}%`}
            </span>
          </div>
        </div>
        <div className="modal-confirm-btn mt-4">
          <PlButton
            label={"Confirm"}
            onClick={() => {
              data
                ? handleTrade()
                : trancheType
                ? handleBuySell()
                : selectedOrder
                  ? handleCancelOrder(selectedOrder)
                  : handleSwap();
              selectedOrder ? setShowModal(false) : onHide();
            }}
          />
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default Confirm;
