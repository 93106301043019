import Select, { components } from "react-select";
import DropdownArrowBlack from "../../assets/images/arrow-dropdown.svg";

const customStyles = {
  control: (base) => ({
    ...base,
    height: 24,
    minHeight: 24,
  }),
};

const WithTagSelect = ({
  label,
  isInput = true,
  placeholder,
  optionValue,
  disabled,
  onChange,
  onSelectChange,
  inputValue,
  value,
  className,
}) => {
  return (
    <>
      <div className={`tag-select ${isInput && "input-wrapper"} ${disabled && "disabled"}`}>
        {label && <label>{label}</label>}
        {isInput && (
          <input
            onKeyPress={(event) => {
              if (event.charCode < 48 && event.charCode !== 46) {
                event.preventDefault();
              }
            }}
            type="number"
            placeholder={placeholder}
            onChange={onChange}
            value={inputValue}
            disabled={disabled}
            className={className}
            min="0"
          />
        )}
        <Select
          styles={customStyles}
          defaultValue={optionValue[0]}
          value={value}
          onChange={onSelectChange}
          options={optionValue}
          isClearable={false}
          hideSelectedOptions
          isDisabled={disabled}
          classNamePrefix="select select-custom"
          components={{
            IndicatorSeparator: null,
            DropdownIndicator: (props) => (
              <components.DropdownIndicator {...props}>
                <img src={DropdownArrowBlack} alt="icon" className="icon" />
              </components.DropdownIndicator>
            ),
          }}
        />
      </div>
    </>
  );
};

export default WithTagSelect;
