import { Tab, Tabs, Modal } from "react-bootstrap";

import closeIcon from "../../../assets/images/modal-close-icon.svg";

import PlButton from "../../../components/buttons/Button";
import WithTag from "../../../components/inputFields/WithTag";
import { PercentageButtons } from "../../../components/buttons/Percentage";

const Deposit = (props) => {
  const { show: data, amount, setAmount, handleDeposit, handleWithdraw, handleApprove, onHide } = props;
  console.log("data--", data);

  return (
    <Modal {...props} size="lg" centered dialogClassName="claim-modal" show={data}>
      <Modal.Body>
        <div className="close-icon" onClick={onHide}>
          <img src={closeIcon} />
        </div>
        <Tabs defaultActiveKey="deposit" id="uncontrolled-tab-example" className="mb-3 earn-details-tabs">
          <Tab eventKey="deposit" title="DEPOSIT">
            <div className="mt-4">
              <WithTag
                label={"Amount"}
                placeholder={"0.0"}
                inputValue={amount}
                onChange={(e) => setAmount(e.target.value)}
                tag={
                  <div className="d-flex align-items-center justify-content-center gap-1">
                    <span className="lamp_tag"></span>LAMP
                  </div>
                }
              />
              <div className="d-flex justify-content-between align-items-center mt-2">
                <span className="balance">Balance : {parseFloat(Number(data.userBalance).toFixed(5))} LAMP</span>
                <PercentageButtons balance={data.userBalance} setAmountIn={setAmount} />
              </div>
            </div>

            <div className="deposite-button mt-4">
              <PlButton
                disabled={Number(amount) <= 0 || Number(amount) > Number(data.userBalance)}
                label={Number(amount) <= 0
                  ? "enter an amount"
                  : Number(amount) > Number(data.userBalance)
                    ? "insufficient funds"
                    : Number(data.approvedBalance) < Number(amount)
                      ? "approve"
                      : "Deposit"}
                onClick={() => {
                  Number(data.approvedBalance) >= Number(amount) && onHide();
                  Number(data.approvedBalance) < Number(amount) ?
                    handleApprove(data.type)
                    : handleDeposit(data.type);
                }} />
            </div>
          </Tab>
          <Tab eventKey="withdraw" title="WITHDRAW">
            <div className="mt-4">
              <WithTag
                label={"Amount"}
                placeholder={"0.0"}
                inputValue={amount}
                onChange={(e) => setAmount(e.target.value)}
                tag={
                  <div className="d-flex align-items-center justify-content-center gap-1">
                    <span className="lamp_tag"></span>LAMP
                  </div>
                }
              />
              <div className="d-flex justify-content-between align-items-center mt-2">
                <span className="balance">Balance : {parseFloat(Number(data.userDeposit).toFixed(5))} LAMP</span>
                <PercentageButtons balance={data.userDeposit} setAmountIn={setAmount} />
              </div>
            </div>

            <div className="deposite-button mt-4">
              <PlButton
                disabled={Number(amount) <= 0 || Number(amount) > Number(data.userDeposit)}
                label={Number(amount) <= 0
                  ? "enter an amount"
                  : Number(amount) > Number(data.userDeposit)
                    ? "insufficient funds"
                    : "Withdraw"}
                onClick={() => {
                  handleWithdraw(data.type);
                  onHide();
                }} />
            </div>
          </Tab>
        </Tabs>
      </Modal.Body>
    </Modal>
  );
};

export default Deposit;
