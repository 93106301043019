import { Row, Modal } from "react-bootstrap";

import closeIcon from "../../../assets/images/modal-close-icon.svg";
import downArrow from "../../../assets/images/arrow_down.svg";
import usdt from "../../../assets/images/usdt-coin.png";

import PlButton from "../../../components/buttons/Button";
import WithTag from "../../../components/inputFields/WithTag";
import { useEffect, useState } from "react";
import { useMetaMask } from "metamask-react";
import { approveTokenSpend, commitTokensForAuction, getTokenBalance, getUserAuctionData } from "../../../io/kava";
import { getRelativeTime } from "../../../common";

const Auction = (props) => {
  const { account } = useMetaMask();
  const { show: data, onHide } = props;

  const [userAuctionData, setUserAuctionData] = useState(null);
  const [tokenBalance, setTokenBalance] = useState(0);
  const [participateAmount, setParticipateAmount] = useState(null);

  useEffect(() => {
    if (data) getUserData();
    else setUserAuctionData(null);
  }, [data, account]);

  const getUserData = async () => {
    const auctionData = await getUserAuctionData(data.address, data.method, account);
    setUserAuctionData(auctionData);
    const bal = await getTokenBalance(data.payTokenAddress, account);
    setTokenBalance(parseFloat(bal));
  };

  const handleApprove = async () => {
    await approveTokenSpend(data.payTokenAddress, data.address, participateAmount);
    await commitTokensForAuction(data.address, data.method, account, participateAmount);
  };

  return (
    <Modal {...props} size="lg" centered dialogClassName="auction-modal" show={data}>
      <Modal.Body>
        <div className="close-icon" onClick={onHide}>
          <img src={closeIcon} />
        </div>

        <div className="modal-title mb-3">
          <h3>LAMP AUCTION</h3>
        </div>

        <div className="d-flex gap-4 justify-content-start mx-0 px-0">
          <div className="lamp_auction_tags">
            <p>Your Spends</p>
            <span>
              {userAuctionData ? parseFloat(userAuctionData.commitments) : "NA"} {data.payToken?.toUpperCase()}
            </span>
          </div>
          <div className="lamp_auction_tags">
            <p>Estimated Received</p>
            <span>
              {userAuctionData ? userAuctionData.claimed : "NA"} {data.auctionAsset?.toUpperCase()}
            </span>
          </div>
        </div>

        <div className="mt-4">
          <p className="earn_details_p d-flex justify-content-between align-items-center mb-3">
            Total Supply{" "}
            <span className="fw-600">
              {data.totalSupply} {data.auctionAsset?.toUpperCase()}
            </span>
          </p>
          <p className="earn_details_p d-flex justify-content-between align-items-center mb-3">
            Total Amount Raised
            <span className="fw-600">
              {parseFloat(data.totalRaised)} {data.payToken?.toUpperCase()}
            </span>
          </p>
          <p className="earn_details_p d-flex justify-content-between align-items-center mb-3">
            Token Remaining<span className="fw-600 loss">NA</span>
          </p>
          <p className="earn_details_p d-flex justify-content-between align-items-center mb-3">
            Ends in<span className="fw-600 liquidity-value ">{getRelativeTime(data.endTime)}</span>
          </p>
        </div>

        <div className="d-flex justify-content-between mb-3">
          <div className=" auction_input_div">
            <WithTag
              inputValue={participateAmount}
              onChange={(e) => setParticipateAmount(e.target.value)}
              label={"Participate"}
              placeholder={"0.0"}
              tag={
                <div className="d-flex align-items-center gap-1">
                  <img src={usdt} height={14} width={14} /> {data.payToken}
                </div>
              }
            />
            <div className="d-flex justify-content-between align-items-center mt-2">
              <span className="balance">
                Balance : {tokenBalance} {data.payToken}
              </span>
              <div className="d-flex gap-2">
                <PlButton type={"percentageButton"} label={"25%"} />
                <PlButton type={"percentageButton"} label={"50%"} />
                <PlButton type={"percentageButton"} label={"75%"} />
                <PlButton type={"percentageButton"} label={"100%"} />
              </div>
            </div>
            <div className="mt-4">
              <p className="earn_details_p d-flex justify-content-between align-items-center mb-3">
                Minimum Receive
                <span className="fw-600 ">
                  {parseFloat(participateAmount) / parseFloat(data.tokenPrice) || "-"} {data.auctionAsset}
                </span>
              </p>
            </div>
            <div className="mt-4">
              <PlButton label={"APPROVE"} onClick={handleApprove} />
            </div>
          </div>

          <div className="price_bar_div">
            <div className="price_compare_bar">
              <div className="starting_price-div">
                <p>
                  Starting Price{" "}
                  <span>
                    {parseFloat(data.startPrice)} {data.payToken?.toUpperCase()}
                  </span>
                </p>
                <div className="circle"></div>
                <div className="line"></div>
              </div>
              <div className="starting_price-div current_price-div">
                <p>
                  Current Price{" "}
                  <span>
                    {parseFloat(data.tokenPrice)} {data.payToken?.toUpperCase()}
                  </span>
                </p>
                <div className="circle"></div>
                <div className="line"></div>
              </div>
              <div className="starting_price-div floor_price-div">
                <p>
                  Floor Price{" "}
                  <span>
                    {parseFloat(data.floorPrice)} {data.payToken?.toUpperCase()}
                  </span>
                </p>
                <div className="circle"></div>
                <div className="line"></div>
              </div>
            </div>
          </div>
        </div>

        <Row className="participants_box mx-0">
          <p className="ps-0">Participants (NA)</p>
          <div className="participants_details px-0">
            <div className="d-flex align-items-center header_div justify-content-center">
              {data.columnTitle?.map((item, i) => (
                <span>
                  {item.title} {item.title !== "Wallet" && <img height={8} width={8} src={downArrow} />}{" "}
                </span>
              ))}
            </div>
            <div className="auction-details-wrapper">
              {data.coulumnData?.map((item, i) => (
                <div className="d-flex align-items-center header_div justify-content-center">
                  <span>{item.wallet}</span>
                  <span className="font_bold">{item.spent}</span>
                  <span className="font_bold">{item.time}</span>
                </div>
              ))}
            </div>
          </div>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default Auction;
