import { ethers } from "ethers";
import { Contract, Provider } from "ethers-multicall";
import { loadingToast } from "../components/toasts/Loading";

import config from "../config.json";

import orderManagerAbi from "./abis/OrderManager.json";
import poolAbi from "./abis/Pool.json";
import levelMasterAbi from "./abis/LevelMasterAbi.json";
import oracleAbi from "./abis/Oracle.json";
import erc20Abi from "./abis/ERC20.json";
import trancheAbi from "./abis/Tranche.json";
import levelZapAbi from "./abis/levelZapAbi.json";

import platformAbi from "./abis/PlatformAbi.json";
import governanceAbi from "./abis/GovernanceAbi.json";

import batchAuctionFactoryAbi from "./abis/BatchAuctionFactoryAbi.json";
import dutchAuctionFactoryAbi from "./abis/DutchAuctionFactoryAbi.json";
import batchAuctionAbi from "./abis/BatchAuctionAbi.json";
import dutchAuctionAbi from "./abis/DutchAuctionAbi.json";

import governanceStakeAbi from "./abis/GovernanceStakeAbi.json";
import platformStakeAbi from "./abis/PlatformStakeAbi.json";
import governanceRedemptionPoolAbi from "./abis/GovernanceRedemptionPoolAbi.json";

import { formatEther, formatUnits, parseEther, parseUnits } from "ethers/lib/utils";
import { providers } from "@0xsequence/multicall";

let provider;
let ethcallProvider;
let multiProvider;

let orderManagerContract, poolContract, levelMasterContract, oracleContract, levelZapContract,
  lgoRedemptionPoolContract;
let tokenContracts = {};
let trancheContracts = {};
let auctionContracts = {};
let earnContracts = {};

//for initialize all contracts and get balance of user's wallet
export const providerHandler = async () => {
  provider = new ethers.providers.Web3Provider(window.ethereum);
  const account = await provider.listAccounts();
  const address = account[0];
  const balance = await provider.getBalance(address);
  const signer = provider.getSigner();

  multiProvider = new providers.MulticallProvider(provider);
  ethcallProvider = new Provider(provider);
  await ethcallProvider.init();

  orderManagerContract = new ethers.Contract(config.trade.orderManager, orderManagerAbi, signer);
  poolContract = new ethers.Contract(config.trade.pool, poolAbi, signer);
  levelMasterContract = new ethers.Contract(config.trade.levelMaster, levelMasterAbi, signer);
  oracleContract = new ethers.Contract(config.trade.oracle, oracleAbi, signer);
  levelZapContract = new ethers.Contract(config.trade.levelZap, levelZapAbi, signer);

  // Token Contracts
  tokenContracts.BTCContract = new ethers.Contract(config.tokens.BTC, erc20Abi, signer);
  tokenContracts.ETHContract = new ethers.Contract(config.tokens.ETH, erc20Abi, signer);
  tokenContracts.KAVAContract = new ethers.Contract(config.tokens.KAVA, erc20Abi, signer);
  tokenContracts.USDTContract = new ethers.Contract(config.tokens.USDT, erc20Abi, signer);
  // tokenContracts.USDContract = new ethers.Contract(config.tokens.USD, erc20Abi, signer);
  tokenContracts.GovernanceContract = new ethers.Contract(config.tokens.Governance, erc20Abi, signer);
  tokenContracts.PlatformContract = new ethers.Contract(config.tokens.Platform, erc20Abi, signer);

  // Tranche Contracts
  trancheContracts.seniorTrancheContract = new ethers.Contract(config.tranches.senior, trancheAbi, signer);
  trancheContracts.mezzanineTrancheContract = new ethers.Contract(config.tranches.mezzanine, trancheAbi, signer);
  trancheContracts.juniorTrancheContract = new ethers.Contract(config.tranches.junior, trancheAbi, signer);

  // Auction Contracts
  auctionContracts.batchAuctionContract = new ethers.Contract(
    config.auction.batchAuctionContract,
    batchAuctionFactoryAbi,
    signer,
  );
  auctionContracts.dutchAuctionContract = new ethers.Contract(
    config.auction.duchAuctionContract,
    dutchAuctionFactoryAbi,
    signer,
  );
  auctionContracts.PTcontract = new ethers.Contract(config.auction.platformContract, platformAbi, signer);
  auctionContracts.GTcontract = new ethers.Contract(config.auction.governanceContract, governanceAbi, signer);

  // Earn Contracts
  earnContracts.governanceStake = new ethers.Contract(config.earn.governanceStake, governanceStakeAbi, signer);
  earnContracts.platformStake = new ethers.Contract(config.earn.platformStake, platformStakeAbi, signer);

  //dao Contracts
  lgoRedemptionPoolContract = new ethers.Contract(
    config.dao.governanceRedemptionPool,
    governanceRedemptionPoolAbi,
    signer,
  );

  return ethers.utils.formatEther(balance.toString());
};

// to get balance of addresses like userAddress and poolAddress from specific token's contract
export const balanceOf = async (tokenType, address) => {
  const contract = `${tokenType}Contract`;
  const n = await tokenContracts[contract].balanceOf(address);
  return formatUnits(n, config.decimals[tokenType]);
};

export const getTokenBalance = async (tokenAddress, userAddress) => {
  const tokenContract = new ethers.Contract(tokenAddress, erc20Abi, provider.getSigner());
  const balance = await tokenContract.balanceOf(userAddress);
  return formatEther(balance.toString());
};

// to get swapFee,positionFee and borrowFee from poolContract
export const fees = async () => {
  let n = await poolContract.fee();
  n = {
    positionFee: formatUnits(n.positionFee.toString(), 10),
    baseSwapFee: formatUnits(n.baseSwapFee.toString(), 10),
    trancheFee: formatUnits(n.taxBasisPoint.toString(), 10),
  };
  return n;
};

export const positionIndex = async (id, tokenType) => {
  const poolMultiContract = new ethers.Contract(config.trade.pool, poolAbi, multiProvider);

  const multiCallArr = [
    await poolMultiContract.positions(id),
    await poolMultiContract.poolTokens(config.tokens[tokenType]),
  ];
  const resultArr = await ethcallProvider.all(multiCallArr);

  return Number(formatUnits(resultArr[0].size, 30)) * (Number(resultArr[0].borrowIndex) - Number(resultArr[1].borrowIndex)) / 10 ** 10;
};

//to get executionFee from orderManagerContract
export const getExecutionFee = async () => {
  let n = await orderManagerContract.minPerpetualExecutionFee();
  return formatEther(n.toString());
};

//to get executionFee from orderManagerContract
export const getSwapExecutionFee = async () => {
  let n = await orderManagerContract.minSwapExecutionFee();
  return formatEther(n.toString());
};

//to get available liquidity on swap page
export const getLiquidity = async (tokenType) => {
  let n = await poolContract.getPoolAsset(config.tokens[tokenType]);
  console.log("liquidity--", Number(n[0]));
  return {
    poolAmount: Number(formatUnits(n[0], config.decimals[tokenType])),
    reserveAmount: Number(formatUnits(n[1], config.decimals[tokenType])),
  };
};

// to calculate swap amount according selected tokens and amount
export const getSwapAmount = async (tokenFrom, tokenTo, amount) => {
  console.log("amount--", amount);
  console.log("tokenFrom--", config.tokens[tokenFrom]);
  console.log("tokenTo--", config.tokens[tokenTo]);
  const n = await poolContract.calcSwapOutput(
    config.tokens[tokenFrom],
    config.tokens[tokenTo],
    parseUnits(parseFloat(Number(amount).toFixed(6)).toString(), config.decimals[tokenFrom]),
  );
  return formatUnits(n.amountOut.toString(), config.decimals[tokenFrom]);
};

export const countSwapFee = async (tokenFrom, tokenTo, amount) => {
  console.log("amount--", amount);
  console.log("tokenFrom--", tokenFrom);
  console.log("tokenTo--", tokenTo);
  const n = await poolContract.calcSwapOutput(
    config.tokens[tokenFrom],
    config.tokens[tokenTo],
    parseEther(parseFloat(Number(amount).toFixed(18)).toString()),
  );
  console.log("n.feeAmount--", Number(n.feeAmount));
  return formatEther(n.feeAmount.toString());
};

//to get token amount which is already approved by user according to spenderAddress like pool and orderManager
export const getApprovedAmount = async (userAddress, tokenType, spenderAddress) => {
  const contract = `${tokenType}Contract`;
  const n = await tokenContracts[contract].allowance(userAddress, spenderAddress);
  return formatUnits(n, config.decimals[tokenType]);
};

// to approve token amount according to spenderAddress like pool and orderManager
export const approve = async (tokenType, spenderAddress) => {
  const contract = `${tokenType}Contract`;
  const n = await tokenContracts[contract].approve(spenderAddress, 10);
  loadingToast(`Approving ${tokenType}`);
  await n.wait();
  return n;
};

//to execute market swap order
export const marketSwap = async (tokenFrom, tokenTo, amountIn, minAmountOut, toInputValue) => {
  console.log("amountIn--", amountIn, tokenFrom);
  console.log("minAmountOut--", minAmountOut.toString(), tokenTo);
  console.log("tokenFrom--address", config.tokens[tokenFrom]);
  console.log("tokenTo--address", config.tokens[tokenTo]);

  const n = await orderManagerContract.swap(
    config.tokens[tokenFrom],
    config.tokens[tokenTo],
    parseUnits(parseFloat(Number(amountIn).toFixed(config.decimals[tokenFrom])).toString(), config.decimals[tokenFrom]),
    parseUnits(parseFloat(Number(minAmountOut).toFixed(config.decimals[tokenFrom])).toString(), config.decimals[tokenFrom]),
  );

  loadingToast(
    `Swapping ${parseFloat(Number(amountIn).toFixed(5))} ${tokenFrom} for ${parseFloat(
      Number(toInputValue).toFixed(5),
    )} ${tokenTo}`,
  );

  await n.wait();
  return n;
};

//to place limit swap order
export const limitSwap = async (tokenFrom, tokenTo, amountIn, minAmountOut, price) => {
  const swapExeFee = await getSwapExecutionFee();
  console.log("amountIn--", amountIn);
  console.log("minAmountOut--", minAmountOut);
  console.log("price--", price);

  const n = await orderManagerContract.placeSwapOrder(
    config.tokens[tokenFrom],
    config.tokens[tokenTo],
    parseUnits(parseFloat(Number(amountIn).toFixed(config.decimals[tokenFrom])).toString(), config.decimals[tokenFrom]),
    parseUnits(parseFloat(Number(minAmountOut).toFixed(config.decimals[tokenFrom])).toString(), config.decimals[tokenFrom]),
    ethers.utils.parseUnits(parseFloat(Number(price).toFixed(12)).toString(), "szabo"),
    { value: parseEther(swapExeFee) },
  );

  loadingToast(
    `Creating order to swap ${parseFloat(Number(amountIn).toFixed(5))} ${tokenFrom} for ${parseFloat(
      Number(minAmountOut).toFixed(5),
    )} ${tokenTo}`,
  );

  await n.wait();
  return n;
};

// to cancel limit order which is placed by user
export const cancelOrder = async (order) => {
  console.log("order.orderId-", order.orderId);
  const n = await orderManagerContract.cancelSwapOrder(order.orderId);

  loadingToast(
    `Cancelling order to swap ${parseFloat(Number(order.priceIn).toFixed(2))} ${order.assetIn} for ${parseFloat(
      Number(order.priceOut).toFixed(2),
    )} ${order.assetOut}`,
  );

  await n.wait();
  return n;
};

export const getTradeData = async (tokenIn, tokenOut, userAddress, side) => {
  console.log("tokenIn #######-", tokenIn);
  console.log("tokenOut #######-", tokenOut);
  console.log("userAddress #######-", userAddress);
  console.log("side #######- 4", side);

  const tokenInContract = new ethers.Contract(config.tokens[tokenIn], erc20Abi, multiProvider);
  const oracleMultiContract = new ethers.Contract(config.trade.oracle, oracleAbi, multiProvider);
  const poolMultiContract = new ethers.Contract(config.trade.pool, poolAbi, multiProvider);
  const orderManagerContract = new ethers.Contract(config.trade.orderManager, orderManagerAbi, multiProvider);

  const multiCallArr = await Promise.all([
    poolMultiContract.getPoolAsset(side === 0 ? config.tokens[tokenOut] : config.tokens.USDT),
    oracleMultiContract.getPrice(config.tokens[tokenOut], true),
    oracleMultiContract.getPrice(config.tokens[tokenIn], true),
    tokenInContract.balanceOf(userAddress),
    tokenInContract.allowance(userAddress, config.trade.orderManager),
    poolMultiContract.maxLeverage(),
    poolMultiContract.fee(),
    orderManagerContract.minPerpetualExecutionFee(),
    orderManagerContract.minSwapExecutionFee(),
  ]);
  console.log("tokenContract.balanceOf(userAddress)-", Number(multiCallArr[3]));
  return {
    borrowFeeRate: Number(formatUnits(multiCallArr[0][1], side === 0 ? config.decimals[tokenOut] : 6)) /
      Number(formatUnits(multiCallArr[0][0], side === 0 ? 6 : config.decimals[tokenOut])) * 0.01,
    entryPrice: formatUnits(multiCallArr[1], 12),
    newEntryPrice: formatUnits(multiCallArr[1], 12),
    TokenAPrice: formatUnits(multiCallArr[2], 12),
    tokenBalance: Number(formatUnits(multiCallArr[3], config.decimals[tokenIn])),
    approvedAmount: Number(formatUnits(multiCallArr[4], config.decimals[tokenIn])),
    availableLiquidity: side === 0 ? tokenOut === "KAVA" ?
      Number(formatUnits(multiCallArr[0][0], config.decimals[tokenOut])) -
      (Number(formatUnits(multiCallArr[0][0], config.decimals[tokenOut])) * 0.7) -
      Number(formatUnits(multiCallArr[0][1], config.decimals[tokenOut]))
      : Number(formatUnits(multiCallArr[0][0], config.decimals[tokenOut])) -
      (Number(formatUnits(multiCallArr[0][0], config.decimals[tokenOut])) * 0.05) -
      Number(formatUnits(multiCallArr[0][1], config.decimals[tokenOut]))
      : Number(formatUnits(multiCallArr[0][0], 6)) - Number(formatUnits(multiCallArr[0][1], 6)),
    maxLeverage: Number(multiCallArr[5]),
    positionFeeRate: ethers.utils.formatUnits(multiCallArr[6].positionFee.toString(), 10),
    executionFee: formatEther(multiCallArr[7]),
    swapExecutionFee: formatEther(multiCallArr[8]),
  };
};

export const getOrderData = async (orderId) => {
  return await orderManagerContract.orders(orderId);
};

//to place order in trade page
export const placeOrder = async (
  updateType,
  side,
  indexToken,
  collateralToken,
  orderType,
  orderPrice,
  orderPayToken,
  purchaseAmount,
  sizeChange,
  feeValue,
  executionFee,
  toastMessage,
  isLimit,
  closeType,
) => {
  console.log("updateType--", updateType);
  console.log("side--", side);
  console.log("indexToken-", indexToken, config.tokens[indexToken]);
  console.log("collateralToken--B", collateralToken, config.tokens[collateralToken]);
  console.log("orderType--", orderType);
  console.log("orderPrice--", orderPrice);
  console.log("orderPayToken--", orderPayToken, config.tokens[orderPayToken]);
  console.log("purchaseAmount--", purchaseAmount);
  console.log("sizeChange--", sizeChange);
  console.log("feeValue--", feeValue);
  console.log("executionFee--", executionFee);
  console.log("isLimit--", isLimit);
  console.log("triggerAboveThreshold--", closeType === "takeProfit");
  const triggerAboveThreshold = side === 0 ? closeType === "takeProfit" : closeType === "stopLoss";

  const extraData = ethers.utils.defaultAbiCoder.encode(
    ["uint256", "uint256", "uint256"],
    [
      parseUnits(sizeChange.toString(), 30),
      parseUnits(parseFloat(Number(purchaseAmount).toFixed(config.decimals[orderPayToken])).toString(), config.decimals[orderPayToken]),
      parseEther(feeValue.toString()),
    ],
  );

  const encodeData = updateType === 0
    //for increase order (new position and deposit)
    // purchaseAmount for increase order = amount of payToken in ETH,BTC,KAVA OR USDT
    ? [
      parseUnits(parseFloat(Number(orderPrice).toFixed(12)).toString(), "szabo"),
      config.tokens[orderPayToken],
      parseUnits(parseFloat(Number(purchaseAmount).toFixed(config.decimals[orderPayToken])).toString(), config.decimals[orderPayToken]),
      sizeChange > 0 ? ethers.utils.parseUnits(sizeChange.toString(), 30) : 0,
      parseUnits(parseFloat(Number(purchaseAmount).toFixed(config.decimals[orderPayToken])).toString(), config.decimals[orderPayToken]),
      extraData,
    ]
    // purchaseAmount for decrease order = collateralValue (sizeChange / leverage ) in USD($)
    : isLimit
      // for limit decrease order (close with stopLoss and takeProfit)
      ? [
        parseUnits(parseFloat(Number(orderPrice).toFixed(12)).toString(), "szabo"),
        triggerAboveThreshold,
        config.tokens[orderPayToken],
        parseUnits(sizeChange.toString(), 30),
        parseUnits(purchaseAmount.toString(), 30), //
        extraData,
      ]
      //for market decrease order (close with market Price and withdraw)
      : [
        parseUnits(parseFloat(Number(orderPrice).toFixed(12)).toString(), "szabo"),
        config.tokens[orderPayToken],
        sizeChange > 0 ? parseUnits(sizeChange.toString(), 30) : 0,
        parseUnits(purchaseAmount.toString(), 30),
        extraData,
      ];

  const n = await orderManagerContract.placeOrder(
    updateType,
    side,
    config.tokens[indexToken],
    config.tokens[collateralToken],
    orderType,
    ethers.utils.defaultAbiCoder.encode(
      updateType === 0
        ? ["uint256", "address", "uint256", "uint256", "uint256", "bytes"]
        : isLimit
        ? ["uint256", "bool", "address", "uint256", "uint256", "bytes"]
        : ["uint256", "address", "uint256", "uint256", "bytes"],
      encodeData,
    ),
    { value: parseEther(executionFee.toString()) },
  );

  loadingToast(toastMessage);

  await n.wait();
  return n;
};

export const getMmRatio = async () => {
  const n = await poolContract?.maintenanceMargin();
  return formatUnits(n, 10);
};

export const getMaxLeverage = async () => {
  const n = await poolContract.maxLeverage();
  return n.toNumber();
};

// to cancel trade order which is placed by user
export const cancelTradeOrder = async (order) => {
  console.log("orderId--", Number(order.id));
  const n = await orderManagerContract.cancelOrder(Number(order.id));

  loadingToast(`Cancelling order to ${order.updateType} ${order.collateralAsset} ${order.side === 0 ? "Long" : "Short"}`);

  await n.wait();
  return n;
};

//to get current price of specific token from oracle contract oracleContract
export const getTokenPrice = async (tokenType) => {
  const n = await oracleContract.getPrice(config.tokens[tokenType], true);
  return formatUnits(n, 12);
};

//to get current price of all tokens from oracle contract
export const getAllTokenPrices = async () => {
  const n = await oracleContract.getMultiplePrices([
    config.tokens.KAVA,
    config.tokens.BTC,
    config.tokens.USDT,
    config.tokens.ETH,
  ], true);
  // return await n.map((value) => ethers.utils.formatEther(value));
  return await n.map((value) => formatUnits(value, 12));
};

//to get target % of al tokens
// export const getTarget = async (tokenType) => {
//   const n = await poolContract.targetWeights(config.tokens[tokenType]);
//   return n / 1000;
// };

// Liquidity Functions
export const getTrancheData = async (tokenType, trancheType, spenderAddress, userAddress) => {
  const multiTokenContract = new ethers.Contract(config.tokens[tokenType], erc20Abi, multiProvider);
  const multiTrancheContract = new ethers.Contract(config.tranches[trancheType], trancheAbi, multiProvider);
  const multiLevelMasterContract = new ethers.Contract(config.trade.levelMaster, levelMasterAbi, multiProvider);
  const multiPoolContract = new ethers.Contract(config.trade.pool, poolAbi, multiProvider);

  const multiCallArr = await Promise.all([
    multiTokenContract.allowance(userAddress, spenderAddress),
    multiTrancheContract.allowance(userAddress, spenderAddress),
    multiTrancheContract.allowance(userAddress, config.trade.levelMaster),
    multiTrancheContract.totalSupply(),
    multiPoolContract.trancheAssets(config.tranches[trancheType], config.tokens[tokenType]),
    multiTrancheContract.balanceOf(userAddress),
    multiPoolContract.targetWeights(config.tokens[tokenType]),
    multiPoolContract.fee(),
    multiLevelMasterContract.userInfo(trancheType === "senior" ? 0 : trancheType === "junior" ? 2 : 1, userAddress),
    multiPoolContract.getTrancheValue(config.tranches[trancheType], true),
    multiLevelMasterContract.pendingReward(trancheType === "senior" ? 0 : trancheType === "junior" ? 2 : 1, userAddress),
  ]);
  console.log("tokenFund--0", Number(multiCallArr[2]));
  return {
    approvedTokenAmount: Number(formatUnits(multiCallArr[0], config.decimals[tokenType])),
    approvedTrancheAmount: Number(formatEther(multiCallArr[1])),
    approvedDepositAmount: Number(formatEther(multiCallArr[2])),
    llpSupply: Number(formatEther(multiCallArr[3])),
    tokenFund: Number(formatUnits(multiCallArr[4].poolAmount, config.decimals[tokenType])) -
      Number(formatUnits(multiCallArr[4].reservedAmount, config.decimals[tokenType])),
    trancheBalance: Number(formatEther(multiCallArr[5])),
    targetValue: multiCallArr[6] / 1000,
    trancheFee: formatUnits(multiCallArr[7].taxBasisPoint.toString(), 10),
    stakingBalance: Number(formatEther(multiCallArr[8].amount)),
    total: Number(formatUnits(multiCallArr[9], 30)),
    unClaimRewards: Number(formatEther(multiCallArr[10])),
  };
};

//get all details of tokens
export const getAssetDetails = async (data, prices, totalValueLocked) => {
  const multiPoolContract = new ethers.Contract(config.trade.pool, poolAbi, multiProvider);

  let new_array = [];
  let target_array = [];
  let resultArr = [];

  for (let i = 0; i < data.length; i++) {
    const n = multiPoolContract.getPoolAsset(config.tokens[data[i].token]);
    const t = multiPoolContract.targetWeights(config.tokens[data[i].token]);
    new_array.push(n);
    target_array.push(t);
  }

  const multiCallArr = await Promise.all(new_array);
  const targetMultiCallArr = await Promise.all(target_array);

  data.map((tokenDetails, i) => {
    console.log("liquidity--");
    const liquidity = Number(formatUnits(multiCallArr[i].poolAmount, config.decimals[tokenDetails.token]));
    const reserve = Number(formatUnits(multiCallArr[i].reservedAmount, config.decimals[tokenDetails.token]));
    console.log("reserve--", reserve);
    tokenDetails.amount = liquidity;
    tokenDetails.price = Number(prices[i]);
    tokenDetails.value = liquidity * Number(prices[i]);
    tokenDetails.target = targetMultiCallArr[i] / 1000;
    tokenDetails.weight = (liquidity * Number(prices[i])) / Number(totalValueLocked) * 100;
    tokenDetails.utilization = reserve / liquidity * 100;
    resultArr.push(tokenDetails);
  });

  return resultArr;
};

//get all details of tokens according to specific tranche
export const getTokensDetails = async (data, prices, trancheAddress) => {
  const multiPoolContract = new ethers.Contract(config.trade.pool, poolAbi, multiProvider);

  let new_array = [];
  const returnData = [];

  for (let i = 0; i < data.length; i++) {
    const n = multiPoolContract.trancheAssets(trancheAddress, config.tokens[data[i].token]);
    new_array.push(n);
  }

  const finalResponse = await Promise.all(new_array);

  data.map((item, i) => {
    const liquidity = Number(formatUnits(finalResponse[i].poolAmount, config.decimals[item.token]));
    const reserve = Number(formatUnits(finalResponse[i].reservedAmount, config.decimals[item.token]));
    item.amount = liquidity;
    item.value = Number(prices[i]) * liquidity;
    item.utilization = reserve / liquidity * 100;
    returnData.push(item);
  });

  return returnData;
};

export const getTranchesDetails = async (tradingTrancheData) => {
  const multiPoolContract = new ethers.Contract(config.trade.pool, poolAbi, multiProvider);

  const trancheContracts = {};
  trancheContracts.seniorMultiContract = new ethers.Contract(config.tranches.senior, trancheAbi, multiProvider);
  trancheContracts.mezzanineMultiContract = new ethers.Contract(config.tranches.mezzanine, trancheAbi, multiProvider);
  trancheContracts.juniorMultiContract = new ethers.Contract(config.tranches.junior, trancheAbi, multiProvider);
  const new_array = [];
  const supply = [];

  for (let i = 0; i < tradingTrancheData.length; i++) {
    const contract = `${tradingTrancheData[i].type}MultiContract`;
    const s = trancheContracts[contract].totalSupply();
    const n = multiPoolContract.getTrancheValue(config.tranches[tradingTrancheData[i].type], true);
    new_array.push(n);
    supply.push(s);
  }

  const finalResponse = await Promise.all(new_array);
  const supplyResponse = await Promise.all(supply);

  const returnData = [];
  await tradingTrancheData.map((trancheData, i) => {
    console.log("totalSupply--", Number(formatEther(supplyResponse[i])));
    trancheData.liquidity = Number(formatUnits(finalResponse[i], 30));
    trancheData.price = Number(formatUnits(finalResponse[i], 30)) / Number(formatEther(supplyResponse[i]));
    returnData.push(trancheData);
  });

  return returnData;
};

//to get supply of all tranche
export const trancheSupply = async () => {
  console.log("poolContract-", poolContract);
  const n = await poolContract.getPoolValue(true);
  return formatUnits(n, 30);
};

//to get balance of user from specific trance contract
export const getTrancheBalance = async (trancheType, userAddress) => {
  const contract = `${trancheType}TrancheContract`;
  const n = await trancheContracts[contract].balanceOf(userAddress);
  return formatEther(n);
};

export const stakeBalance = async (trancheId, userAddress) => {
  const n = await levelMasterContract.userInfo(trancheId, userAddress);
  return formatEther(n.amount);
};

//to get tranche amount which is already approved by user according to spenderAddress like pool
export const approvedTranche = async (trancheType, userAddress, spenderAddress) => {
  const contract = `${trancheType}TrancheContract`;
  const n = await trancheContracts[contract].allowance(userAddress, spenderAddress);
  return formatEther(n);
};

// to approve tranche amount according to spenderAddress like pool
export const poolApprove = async (trancheType, spenderAddress) => {
  const contract = `${trancheType}TrancheContract`;
  const n = await trancheContracts[contract].approve(spenderAddress, 10);

  loadingToast(`Approving ${trancheType === "senior" ? "Senior" : trancheType === "junior" ? "Junior" : "Mezzanine"} llp`);
  await n.wait();
  return n;
};

//to calculate Liquidity from token to tranche
export const calcAddLiquidity = async (trancheAddress, tokenType, amountIn) => {
  console.log("trancheAddress--", trancheAddress);
  console.log("amountIn--", amountIn);
  // console.log("tokenType--", config.tokens[tokenType]);

  const n = await poolContract.calcAddLiquidity(
    trancheAddress,
    config.tokens[tokenType],
    parseUnits(parseFloat(Number(amountIn).toFixed(18)).toString(), 18),
  );

  return {
    lpAmount: Number(n.lpAmount) / 10 ** 18,
    daoFee: formatEther(n.daoFee.toString()),
  };
};

//to calculate Liquidity from tranche to token
export const calcRemoveLiquidity = async (trancheAddress, tokenType, lpAmount) => {
  console.log("trancheAddress--", trancheAddress);
  console.log("lpAmount--", lpAmount);
  console.log("tokenType--", tokenType);

  const n = await poolContract.calcRemoveLiquidity(
    trancheAddress,
    config.tokens[tokenType],
    // parseEther(lpAmount.toString()),
    parseEther(parseFloat(Number(lpAmount).toFixed(18)).toString()),
  );
  return {
    lpAmount: formatEther(n.outAmountAfterFee.toString()),
    daoFee: formatEther(n.feeAmount.toString()),
  };
};

//to buy tranche amount
export const addLiquidity = async (trancheType, tokenType, amountIn, minimumAmount, userAddress, amountOut, isSwitchOn) => {
  let n;
  console.log("trancheType--", trancheType === "senior" ? 0 : trancheType === "mezzanine" ? 1 : 2);
  console.log("minimumAmount--", minimumAmount);
  console.log("minimumAmount--", amountIn);
  console.log("userAddress--", userAddress);
  console.log("isSwitchOn--", isSwitchOn);

  if (isSwitchOn) {
    // if (tokenType === "KAVA") {
    //   console.log("####### -  levelMaster add eth liquidity");
    //   n = await levelMasterContract.addLiquidityETH(
    //     trancheType === "senior" ? 0 : trancheType === "mezzanine" ? 1 : 2,
    //     ethers.utils.parseEther(parseFloat(Number(minimumAmount).toFixed(18)).toString()),
    //     userAddress,
    //     { value: ethers.utils.parseEther(parseFloat(Number(amountIn).toFixed(18)).toString()) },
    //   );
    // } else {
    console.log("####### - levelMaster add liquidity");
    n = await levelMasterContract.addLiquidity(
      trancheType === "senior" ? 0 : trancheType === "mezzanine" ? 1 : 2,
      config.tokens[tokenType],
      parseUnits(parseFloat(Number(amountIn).toFixed(config.decimals[tokenType])).toString(), config.decimals[tokenType]),
      parseUnits(parseFloat(Number(minimumAmount).toFixed(config.decimals[tokenType])).toString(), config.decimals[tokenType]),
      userAddress,
    );
    // }
  } else {
    console.log("####### - pool add liquidity");
    n = await poolContract.addLiquidity(
      config.tranches[trancheType],
      config.tokens[tokenType],
      parseUnits(parseFloat(Number(amountIn).toFixed(config.decimals[tokenType])).toString(), config.decimals[tokenType]),
      parseUnits(parseFloat(Number(minimumAmount).toFixed(config.decimals[tokenType])).toString(), config.decimals[tokenType]),
      userAddress,
    );
  }

  loadingToast(
    ` Selling ${parseFloat(Number(amountIn).toFixed(5))} ${tokenType}  to get ${parseFloat(Number(amountOut).toFixed(5))} ${
      trancheType === "senior" ? "Senior" : trancheType === "junior" ? "Junior" : "Mezzanine"} LLP`,
  );

  await n.wait();
  return n;
};

//to sell tranche amount
export const removeLiquidity = async (trancheType, tokenType, lpAmountIn, minimumAmount, userAddress, amountOut, isSwitchOn) => {
  console.log("userAddress-", userAddress);
  console.log("lpAmountIn-", lpAmountIn);
  console.log("minimumAmount-", minimumAmount);
  console.log("trancheType-", trancheType);
  console.log("tokenType-", tokenType);
  let n;
  if (isSwitchOn) {
    // if (tokenType === "KAVA") {
    //   n = await levelMasterContract.removeLiquidityETH(
    //     trancheType === "senior" ? 0 : trancheType === "mezzanine" ? 1 : 2,
    //     ethers.utils.parseEther(parseFloat(Number(lpAmountIn).toFixed(18)).toString()),
    //     ethers.utils.parseEther(parseFloat(Number(minimumAmount).toFixed(18)).toString()),
    //     userAddress,
    //   );
    // } else {
    n = await levelMasterContract.removeLiquidity(
      trancheType === "senior" ? 0 : trancheType === "mezzanine" ? 1 : 2,
      parseUnits(parseFloat(Number(lpAmountIn).toFixed(config.decimals[tokenType])).toString(), config.decimals[tokenType]),
      parseUnits(parseFloat(Number(minimumAmount).toFixed(config.decimals[tokenType])).toString(), config.decimals[tokenType]),
      userAddress,
    );
    // }
  } else {
    n = await poolContract.removeLiquidity(
      config.tranches[trancheType],
      config.tokens[tokenType],
      parseUnits(parseFloat(Number(lpAmountIn).toFixed(config.decimals[tokenType])).toString(), config.decimals[tokenType]),
      parseUnits(parseFloat(Number(minimumAmount).toFixed(config.decimals[tokenType])).toString(), config.decimals[tokenType]),
      userAddress,
    );
  }

  loadingToast(
    ` Selling ${parseFloat(Number(lpAmountIn).toFixed(5))} ${
      trancheType === "senior" ? "Senior" : trancheType === "junior" ? "Junior" : "Mezzanine"
    } LLP to get ${parseFloat(Number(amountOut).toFixed(5))} ${tokenType}`,
  );

  await n.wait();
  return n;
};

export const depositTranche = async (trancheId, amount, userAddress) => {
  console.log("amount-", amount);
  console.log("userAddress-", userAddress);
  console.log("trancheId-", trancheId);
  const n = await levelMasterContract.deposit(trancheId, parseEther(amount.toString()), userAddress);
  loadingToast(`Depositing ${amount} ${trancheId === 0 ? "Senior" : trancheId === 2 ? "Junior" : "Mezzanine"} llp.`);
  await n.wait();
  return n;
};

export const withdrawTranche = async (trancheId, amount, userAddress) => {
  const n = await levelMasterContract.withdraw(trancheId, parseEther(amount.toString()), userAddress);
  loadingToast(`Withdrawing ${amount} ${trancheId === 0 ? "Senior" : trancheId === 2 ? "Junior" : "Mezzanine"} llp.`);
  await n.wait();
  return n;
};

export const trancheRewards = async (trancheId, userAddress) => {
  console.log("trancheId--", trancheId);
  console.log("userAddress--", userAddress);
  console.log("levelMasterContract-", levelMasterContract);
  const n = await levelMasterContract.pendingReward(trancheId, userAddress);
  return formatEther(n.toString());
};

export const trancheClaim = async (trancheId, userAddress) => {
  console.log("trancheId--", trancheId);
  console.log("userAddress--", userAddress);
  const n = await levelMasterContract.harvest(trancheId, userAddress);
  loadingToast(`Claiming Lamp`);
  await n.wait();
  return n;
};


export const fetchLampKavaDetails = async (userAddress) => {
  const new_array = [];

  return {};
};

export const calcKavaAmountOut = async (reserveIn, amountIn) => {
  console.log("levelZapContract-", levelZapContract);
  const n = await levelZapContract.calculateSwapInAmount(parseEther(reserveIn), parseEther(amountIn));
  return formatEther(n);
};

export const addLampLiquidity = async (amountOut, minimumReceived) => {
  console.log("amountOut--", amountOut);
  const n = await levelZapContract.zap(0, parseEther(minimumReceived), true);
  loadingToast(`Depositing ${amountOut}  KAVA`);
  await n.wait();
  return n;
};

// Auction Functions
export const getAuctions = async () => {
  const currentTime = parseInt(Date.now() / 1000);
  const signer = provider.getSigner();

  const batchAuctions = await auctionContracts.batchAuctionContract.totalAuctions().then((res) => {
    return res.toNumber();
  });
  const dutchAuctions = await auctionContracts.dutchAuctionContract.totalAuctions().then((res) => {
    return res.toNumber();
  });

  let batchAuctionAddresses = [];
  let dutchAuctionAddresses = [];

  const liveAuction = [];
  const upcomingAuction = [];
  const finishedAuction = [];

  if (batchAuctions) {
    const batchAuctionMultiCall = new Contract(config.auction.batchAuctionContract, batchAuctionFactoryAbi);

    const multicallArr = [];
    for (let i = 0; i < batchAuctions; i++) multicallArr.push(await batchAuctionMultiCall.auctions(i));

    batchAuctionAddresses = await ethcallProvider.all(multicallArr);
  }

  for (let i = 0; i < batchAuctions; i++) {
    let auctionData = {
      address: batchAuctionAddresses[i],
      method: "Batch Auction",
    };

    const batchAuctionContract = new Contract(batchAuctionAddresses[i], batchAuctionAbi);

    const multicallArr = [
      await batchAuctionContract.endTime(),
      await batchAuctionContract.startTime(),
      await batchAuctionContract.auctionToken(),
      await batchAuctionContract.totalTokens(),
      await batchAuctionContract.payToken(),
      await batchAuctionContract.startPrice(),
      await batchAuctionContract.vestingDuration(),
      await batchAuctionContract.tokenPrice(),
      await batchAuctionContract.minimumPrice(),
      await batchAuctionContract.commitmentsTotal(),
    ];

    const resultArr = await ethcallProvider.all(multicallArr);

    auctionData.endTime = resultArr[0].toNumber();
    auctionData.startTime = resultArr[1].toNumber();
    auctionData.auctionAssetAddress = resultArr[2];
    auctionData.totalSupply = formatEther(resultArr[3]);
    auctionData.payTokenAddress = resultArr[4];
    auctionData.startPrice = formatEther(resultArr[5]);
    auctionData.vesting = resultArr[6];
    auctionData.tokenPrice = formatEther(resultArr[6]);
    auctionData.floorPrice = formatEther(resultArr[7]);
    auctionData.totalRaised = formatEther(resultArr[8]);

    const auctionAssetContract = new ethers.Contract(resultArr[2], erc20Abi, signer);
    auctionData.auctionAsset = await auctionAssetContract.symbol();

    const payTokenContract = new ethers.Contract(resultArr[4], erc20Abi, signer);
    auctionData.payToken = await payTokenContract.symbol();

    if (auctionData.endTime > currentTime && auctionData.startTime < currentTime) liveAuction.push(auctionData);

    if (auctionData.endTime > currentTime && auctionData.startTime > currentTime) upcomingAuction.push(auctionData);

    if (auctionData.endTime < currentTime && auctionData.startTime < currentTime) finishedAuction.push(auctionData);
  }

  if (dutchAuctions) {
    const dutchAuctionMultiCall = new Contract(config.auction.duchAuctionContract, dutchAuctionFactoryAbi);

    const multicallArr = [];
    for (let i = 0; i < dutchAuctions; i++) multicallArr.push(await dutchAuctionMultiCall.auctions(i));

    dutchAuctionAddresses = await ethcallProvider.all(multicallArr);
  }

  for (let i = 0; i < dutchAuctions; i++) {
    let auctionData = {
      address: dutchAuctionAddresses[i],
      method: "Dutch Auction",
      vesting: false,
    };

    const dutchAuctionContract = new Contract(dutchAuctionAddresses[i], dutchAuctionAbi);

    const multicallArr = [
      await dutchAuctionContract.endTime(),
      await dutchAuctionContract.startTime(),
      await dutchAuctionContract.auctionToken(),
      await dutchAuctionContract.totalTokens(),
      await dutchAuctionContract.payToken(),
      await dutchAuctionContract.startPrice(),
      await dutchAuctionContract.tokenPrice(),
      await dutchAuctionContract.minimumPrice(),
      await dutchAuctionContract.commitmentsTotal(),
    ];

    const resultArr = await ethcallProvider.all(multicallArr);

    auctionData.endTime = resultArr[0].toNumber();
    auctionData.startTime = resultArr[1].toNumber();
    auctionData.auctionAssetAddress = resultArr[2];
    auctionData.totalSupply = formatEther(resultArr[3]);
    auctionData.payTokenAddress = resultArr[4];
    auctionData.startPrice = formatEther(resultArr[5]);
    auctionData.tokenPrice = formatEther(resultArr[6]);
    auctionData.floorPrice = formatEther(resultArr[7]);
    auctionData.totalRaised = formatEther(resultArr[8]);

    const auctionAssetContract = new ethers.Contract(resultArr[2], erc20Abi, signer);
    auctionData.auctionAsset = await auctionAssetContract.symbol();

    const payTokenContract = new ethers.Contract(resultArr[4], erc20Abi, signer);
    auctionData.payToken = await payTokenContract.symbol();

    if (auctionData.endTime > currentTime && auctionData.startTime < currentTime) liveAuction.push(auctionData);

    if (auctionData.endTime > currentTime && auctionData.startTime > currentTime) upcomingAuction.push(auctionData);

    if (auctionData.endTime < currentTime && auctionData.startTime < currentTime) finishedAuction.push(auctionData);
  }

  return {
    liveAuction: liveAuction,
    upcomingAuction: upcomingAuction,
    finishedAuction: finishedAuction,
  };
};

export const getUserAuctionData = async (auctionAddress, method, account) => {
  const auctionContract = new Contract(auctionAddress, method === "Dutch Auction" ? dutchAuctionAbi : batchAuctionAbi);

  const resultArr = await ethcallProvider.all([
    await auctionContract.commitments(account),
    await auctionContract.claimed(account),
  ]);

  return {
    commitments: formatEther(resultArr[0]),
    claimed: formatEther(resultArr[1]),
  };
};

export const approveTokenSpend = async (tokenAddress, spenderAddress, amount) => {
  const signer = provider.getSigner();
  const tokenContract = new ethers.Contract(tokenAddress, erc20Abi, signer);

  const n = await tokenContract.approve(spenderAddress, amount);
  await n.wait();

  return n;
};

export const commitTokensForAuction = async (auctionAddress, method, userAddress, amount) => {
  const signer = provider.getSigner();
  const auctionContract = new ethers.Contract(
    auctionAddress,
    method === "Dutch Auction" ? dutchAuctionAbi : batchAuctionAbi,
    signer,
  );

  const n = await auctionContract.commitTokens(userAddress, parseEther(amount));
  await n.wait();

  return n;
};

// Earn Functions
export const getEarnData = async (account) => {
  const governanceTokenContract = new ethers.Contract(config.tokens.Governance, erc20Abi, multiProvider);
  const platformTokenContract = new ethers.Contract(config.tokens.Platform, erc20Abi, multiProvider);
  const governanceStakeContract = new ethers.Contract(config.earn.governanceStake, governanceStakeAbi, multiProvider);
  const platformStakeContract = new ethers.Contract(config.earn.platformStake, platformStakeAbi, multiProvider);

  const multicallArr = [
    governanceTokenContract.balanceOf(config.earn.governanceStake),
    platformTokenContract.balanceOf(config.earn.platformStake),
    governanceStakeContract.getRewardsPerSecond(),
    platformStakeContract.rewardsPerSecond(),
    platformStakeContract.stakingTax(),
  ];

  if (account) {
    multicallArr.push(
      governanceStakeContract.userInfo(account),
      platformStakeContract.userInfo(account),
      governanceStakeContract.pendingRewards(account),
      platformStakeContract.pendingRewards(account),
      governanceTokenContract.balanceOf(account),
      platformTokenContract.balanceOf(account),
      governanceTokenContract.allowance(account, config.earn.governanceStake),
      platformTokenContract.allowance(account, config.earn.platformStake),
    );
  }
  const resultArr = await Promise.all(multicallArr);

  return {
    governance: {
      deposit: formatEther(resultArr[0]),
      reward: formatEther(resultArr[2].mul(86400)),
      userDeposit: formatEther(resultArr[5].amount),
      userReward: formatEther(resultArr[7]),
      userBalance: formatEther(resultArr[9]),
      approvedBalance: formatEther(resultArr[11]),
    },
    platform: {
      deposit: formatEther(resultArr[1]),
      reward: formatEther(resultArr[3].mul(86400)),
      userDeposit: formatEther(resultArr[6].amount),
      userReward: formatEther(resultArr[8]),
      stakingTax: formatEther(resultArr[4]),
      userBalance: formatEther(resultArr[10]),
      approvedBalance: formatEther(resultArr[12]),
    },
  };
};

export const depositedGovernance = async () => {
  const n = await tokenContracts.GovernanceContract.balanceOf(config.earn.governanceStake);
  return formatEther(n);
};

export const depositedPlatform = async () => {
  const n = await tokenContracts.PlatformContract.balanceOf(config.earn.platformStake);
  return formatEther(n);
};

export const approvedGovernance = async (userAddress) => {
  const n = await tokenContracts.GovernanceContract.allowance(userAddress, config.earn.governanceStake);
  return formatEther(n);
};

export const approvedPlatform = async (userAddress) => {
  const n = await tokenContracts.PlatformContract.allowance(userAddress, config.earn.platformStake);
  return formatEther(n);
};

export const governanceApprove = async () => {
  const n = await tokenContracts.GovernanceContract.approve(config.earn.governanceStake, 10);
  loadingToast("Approving Governance.");
  await n.wait();
  return n;
};

export const platformApprove = async () => {
  const n = await tokenContracts.PlatformContract.approve(config.earn.platformStake, 10);
  loadingToast("Approving Platform.");
  await n.wait();
  return n;
};


export const governanceDeposit = async (account, amount) => {
  const n = await earnContracts.governanceStake.stake(account, parseEther(amount.toString()));
  loadingToast(`Depositing ${amount} Governance.`);
  await n.wait();

  return n;
};

export const governanceWithdraw = async (account, amount) => {
  const n = await earnContracts.governanceStake.unstake(account, parseEther(amount.toString()));
  loadingToast(`Withdrawing ${amount} Governance.`);
  await n.wait();
  return n;
};

export const governanceClaim = async (account) => {
  const n = await earnContracts.governanceStake.claimRewards(account);
  loadingToast(`Claiming Governance.`);
  await n.wait();

  return n;
};

export const platformDeposit = async (account, amount) => {
  console.log("amount-", amount);
  const n = await earnContracts.platformStake.stake(account, parseEther(amount.toString()));
  loadingToast(`Depositing ${amount} Platform.`);
  await n.wait();

  return n;
};

export const platformWithdraw = async (account, amount) => {
  const n = await earnContracts.platformStake.unstake(account, parseEther(amount.toString()));
  loadingToast(`Withdrawing ${amount} Platform.`);
  await n.wait();

  return n;
};

export const platformClaim = async (account) => {
  const n = await earnContracts.platformStake.claimRewards(account);
  loadingToast("Claiming Platform.");
  await n.wait();

  return n;
};

// Dao functions
export const getLvlBalance = async (userAddress) => {
  const n = await auctionContracts.PTcontract.balanceOf(userAddress);
  return ethers.utils.formatEther(n);
};

export const getApprovedLvl = async (userAddress, spenderAddress) => {
  console.log("tokenContracts.LAMPContract-", tokenContracts.PlatformContract);
  const n = await auctionContracts.PTcontract.allowance(userAddress, spenderAddress);
  return ethers.utils.formatEther(n.toString());
};

export const lvlApprove = async (spenderAddress) => {
  const n = await auctionContracts.PTcontract.approve(spenderAddress, 10);
  loadingToast(`Approving Lamp`);
  await n.wait();
  return n;
};

export const getStakedBalance = async (userAddress) => {
  const n = await earnContracts.platformStake.userInfo(userAddress);
  return ethers.utils.formatEther(n.amount.toString());
};

export const getPendingRewards = async (userAddress) => {
  const n = await earnContracts.platformStake.pendingRewards(userAddress);
  return ethers.utils.formatEther(n);
};

export const lvlStake = async (userAddress, amount) => {
  const n = await earnContracts.platformStake.stake(userAddress, parseEther(amount));
  loadingToast(`Staking ${amount} Lamp`);
  await n.wait();

  return n;
};

export const lvlUnstake = async (userAddress, amount) => {
  const n = await earnContracts.platformStake.unstake(userAddress, parseEther(amount));
  loadingToast(`Unstaking ${amount} Lamp`);
  await n.wait();

  return n;
};

export const lvlClaimRewards = async (userAddress) => {
  console.log("userAddress-", userAddress);
  const n = await earnContracts.platformStake.claimRewards(userAddress);
  await n.wait();

  return n;
};

export const getLgoBalance = async (userAddress) => {
  const n = await auctionContracts.GTcontract.balanceOf(userAddress);
  return ethers.utils.formatEther(n);
};

export const getApprovedLgo = async (userAddress, spenderAddress) => {
  const n = await auctionContracts.GTcontract.allowance(userAddress, spenderAddress);
  return ethers.utils.formatEther(n.toString());
};

export const lgoApprove = async (spenderAddress) => {
  const n = await auctionContracts.GTcontract.approve(spenderAddress, 10);
  loadingToast(`Approving LGO`);
  await n.wait();
  return n;
};

export const lvlRedeem = async (userAddress, amount) => {
  const n = await lgoRedemptionPoolContract.redeem(userAddress, parseEther(amount));
  await n.wait();

  return n;
};
