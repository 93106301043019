// Will mostly have only UI component imports and placement in this file.

import { Card, Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import arrowSvg from "../../../assets/images/arrow-forward.svg";
import flash from "../../../assets/images/flash.svg";

import PlButton from "../../../components/buttons/Button";
import { ReactComponent as InfoTag } from "../../../assets/images/info_outline.svg";
import { toolTipData } from "../../../components/static/ToolTipData";
import ReactTooltip from "react-tooltip";

const TradingTranche = ({ tranchesDetails }) => {
  const navigate = useNavigate();

  return (
    <>
      <Row>
        <div className="trading-section">
          <p className="title">Trading Pools</p>
          {tranchesDetails?.map((item, id) => (
            <Col key={id} className="pools_column">
              <Card className="shadowed-box earn_card pointer-cursor"
                    onClick={() => navigate(`/liquidity/${item.type}`)}>
                <Card.Body className="liquidity-card-wrapper">
                  <div className="d-flex justify-content-between aligh-center">
                    <div className="liquidity-card-content">
                      <h2 className="d-flex align-items-center justify-content-start gap-2">
                        {item.title}{" "}
                        <span className={item.classname}>
                          <img src={flash} />
                          {item.risk}
                        </span>
                      </h2>
                      <h6>
                        {item.value}
                        <span className="ms-2"></span>
                      </h6>
                    </div>
                    <div className="liquidity-card-content">
                      <p>Earn</p>
                      <span>
                        <span className="liquidity-value">{item.earnValue}</span>
                        <span className="ms-2">per day</span>
                      </span>
                    </div>
                    <div className="liquidity-card-content">
                      <p className="d-flex align-items-center gap-1">APR <InfoTag
                        className="info_icon tranche_info" data-tip={toolTipData.trancheApr} /></p>
                      <span>{item.aprValue}</span>
                    </div>
                    <div className="liquidity-card-content">
                      <p className="d-flex align-items-center gap-1">Liquidity <InfoTag
                        className="info_icon tranche_info" data-tip={toolTipData.poolLiquidity} /></p>
                      <span>{`$${Math.round(Number(item.liquidity)).toLocaleString()}`}</span>
                    </div>
                    <div className="liquidity-card-content">
                      <p className="d-flex align-items-center gap-1">Price <InfoTag
                        className="info_icon tranche_info"
                        data-tip={item.type === "senior"
                          ? toolTipData.seniorTranchePrice : item.type === "junior"
                            ? toolTipData.juniorTranchePrice : toolTipData.mezzanineTranchePrice} />
                      </p>
                      <span>{`$${parseFloat(item.price.toFixed(3))}`}</span>
                    </div>
                    <div className="liquidity-card-arrowIcon d-flex align-items-center">
                      <PlButton src={arrowSvg} className="sqaure" height={14} width={14} />
                    </div>
                  </div>
                </Card.Body>
              </Card>
              <ReactTooltip backgroundColor="black" className="react_tooltip" arrowColor="black" place="top" type="dark"
                            effect="solid" />
            </Col>
          ))}
        </div>
      </Row>
    </>
  );
};

export default TradingTranche;
