const WithTag = ({
  label,
  isInput = true,
  placeholder,
  subValue,
  disabled,
  onChange,
  inputValue,
  tag,
  className,
  inputClass,
  modalInout
}) => {
  return (
    <>
      <div className={`tag-select ${isInput && "input-wrapper"} ${disabled && "disabled"} ${subValue && "modal-input"}`}>
        {label && <label>{label}</label>}
          {isInput && (
            <input
              type="number"
              value={inputValue}
              min="0"
              onKeyPress={(event) => {
                if (event.charCode < 48 && event.charCode !== 46) {
                  event.preventDefault();
                }
              }}
              className={inputClass}
              placeholder={placeholder}
              onChange={onChange}
              disabled={disabled}
            />
          )}
        <span className={`fixed-tag ${className}`}>{tag}</span>
        {subValue && <span className="sub-value">{subValue}</span>}
      </div>
    </>
  );
};

export default WithTag;
