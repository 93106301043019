// Will mostly have only UI component imports and placement in this file.

import { useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import DepositWithdraw from "../modals/DepositWithdraw";
import {
  approvedTranche,
  depositTranche,
 getTrancheBalance,
  poolApprove,
  stakeBalance,
  withdrawTranche,
} from "../../../io/kava";
import { waitingToast } from "../../../components/toasts/Waiting";
import { handleError } from "../../../components/toasts/Error";
import { useCommonStateContext } from "../../../hooks/commonStateContext";
import { successToast } from "../../../components/toasts/Success";
import configs from "../../../config.json";
import { useMetaMask } from "metamask-react";

const Metrics = ({ trancheMetricsData, trancheConditions, setTrancheConditions, trancheType }) => {
  const [modalOpen, setModalOpen] = useState(false); // to manage deposit.withdraw modal
  const [modalDetails, setModalDetails] = useState({ title: "", balance: "", label: "", approvedBalance: 0 });
  const [modalAmount, setModalAmount] = useState("");
  const { setLoading } = useCommonStateContext();
  const { account } = useMetaMask();

  const handleOpenModal = (item) => {
    console.log('trancheConditions-',trancheConditions);
    setModalDetails({
      title: item.tag,
      balance: item.value,
      label: item.label,
      approvedBalance: trancheConditions?.approvedDepositAmount,
    });
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalAmount("");
    setModalDetails({ title: "", balance: "", label: "", approvedBalance: 0 });
    setModalOpen(false);
  };

  const handleApprove = async () => {
    setLoading(true);
    try {
      waitingToast(`Approving ${trancheType === "senior" ? "Senior" : trancheType === "junior" ? "Junior" : "Mezzanine"} llp`);

      await poolApprove(trancheType, configs.trade.levelMaster);

      successToast(`${trancheType === "senior" ? "Senior" : trancheType === "junior" ? "Junior" : "Mezzanine"} llp Approved!`);

      const trancheAmt = await approvedTranche(trancheType, account, configs.trade.levelMaster);

      setTrancheConditions({
        ...trancheConditions,
        approvedDepositAmount: Number(trancheAmt),
      });

      setModalDetails({
        ...modalDetails,
        approvedBalance: Number(trancheAmt),
      });

    } catch (e) {
      handleError(e);
    }
    setLoading(false);
  };

  const fetchBalances = async () => {
    const stakingBalance = await stakeBalance(trancheType === "senior" ? 0 : trancheType === "junior" ? 2 : 1, account);
    const walletBalance = await getTrancheBalance(trancheType, account);

    setTrancheConditions({
      ...trancheConditions,
      stakingBalance,
      trancheBalance: Number(walletBalance),
    });
  };

  const handleDeposit = async () => {
    setLoading(true);
    try {
      waitingToast(`Depositing ${modalAmount} ${trancheType === "senior" ? "Senior" : trancheType === "junior" ? "Junior" : "Mezzanine"} llp.`);
      const res = await depositTranche(trancheType === "senior" ? 0 : trancheType === "junior" ? 2 : 1, modalAmount, account);
      await fetchBalances();
      successToast(`${modalAmount} ${trancheType === "senior" ? "Senior" : trancheType === "junior" ? "Junior" : "Mezzanine"} llp deposited.`);
    } catch (e) {
      handleError(e);
    }
    setLoading(false);
  };

  const handleWithdraw = async () => {
    setLoading(true);
    try {
      waitingToast(`Withdrawing ${modalAmount} ${trancheType === "senior" ? "Senior" : trancheType === "junior" ? "Junior" : "Mezzanine"} llp.`);
      const res = await withdrawTranche(trancheType === "senior" ? 0 : trancheType === "junior" ? 2 : 1, modalAmount, account);
      await fetchBalances();
      successToast(`${modalAmount} ${trancheType === "senior" ? "Senior" : trancheType === "junior" ? "Junior" : "Mezzanine"} llp withdrew.`);
    } catch (e) {
      handleError(e);
    }
    setLoading(false);
  };

  return (
    <>
      <Row xs={1} md={4} className="cards-deatils liquidity-details-cards">
        {trancheMetricsData.map((item, id) => (
          <Col key={id}>
            <Card className="shadowed-box p-0 pools_card">
              <Card.Body>
                <div>
                  <p className="mb-2">{item.title}</p>
                  <span>
                    {item.value} {item?.label}
                  </span>
                  <p className="card_desc_span">{item.desc}</p>
                  {item.tag && (
                    <p onClick={() => handleOpenModal(item)} className="card_desc_tag">
                      {item.tag}
                    </p>
                  )}
                </div>
                {item.img}
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>

      {modalOpen && (
        <DepositWithdraw
          modalAmount={modalAmount}
          setModalAmount={setModalAmount}
          modalDetails={modalDetails}
          show={modalOpen}
          onHide={handleCloseModal}
          handleDeposit={handleDeposit}
          trancheConditions={trancheConditions}
          handleWithdraw={handleWithdraw}
          handleApprove={handleApprove}
        />
      )}
    </>
  );
};

export default Metrics;
