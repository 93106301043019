import { Col } from "react-bootstrap";

import { useCommonStateContext } from "../../../hooks/commonStateContext";
import { trancheClaim, trancheRewards } from "../../../io/kava";

import PlButton from "../../../components/buttons/Button";
import WithTag from "../../../components/inputFields/WithTag";

import { waitingToast } from "../../../components/toasts/Waiting";
import { successToast } from "../../../components/toasts/Success";
import { handleError } from "../../../components/toasts/Error";
import { useMetaMask } from "metamask-react";

const Claim = ({ trancheConditions, setTrancheConditions, trancheType }) => {
  const { setLoading } = useCommonStateContext();
  const { account } = useMetaMask();

  const handleClaim = async () => {
    setLoading(true);
    try {
      waitingToast(`Claiming Lamp`);
      const res = await trancheClaim(trancheType === "senior" ? 0 : trancheType === "mezzanine" ? 1 : 2, account);

      successToast(`Lamp claimed.`);
      const unclaimBalance = await trancheRewards(trancheType === "senior" ? 0 : trancheType === "junior" ? 2 : 1, account);
      setTrancheConditions({
        ...trancheConditions,
        unClaimRewards: unclaimBalance,
      });
    } catch (e) {
      handleError(e);
    }

    setLoading(false);
  };

  return (
    <>
      <Col className="mt-2">
        <div className="shadowed-box card-main-content">
          <div className="card-title mb-3">
            <h3>CLAIM</h3>
          </div>
          <div className="mt-4">
            <WithTag
              inputClass={"cursor_not_allowed"}
              tag={
                <div className="d-flex align-items-center gap-1">
                  <div className="lamp_tag eth_lamp"></div>
                  LAMP
                </div>
              }
              label={"Amount"}
              placeholder={"0.0"}
              inputValue={parseFloat(Number(trancheConditions.unClaimRewards).toFixed(5))}
            />
          </div>

          <div className="modal-confirm-btn mt-4">
            <PlButton
              disabled={Number(trancheConditions.unClaimRewards) === 0}
              label={"CLAIM"} onClick={handleClaim} />
          </div>
        </div>
      </Col>
    </>
  );
};

export default Claim;
