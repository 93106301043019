import { useEffect, useState } from "react";
import { Nav, Navbar } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { useMetaMask } from "metamask-react";

import logo from "../assets/images/perplamp-logo.png";
import person from "../assets/images/person.svg";

import wallet from "../assets/images/wallet.svg";
import { useWeb3ConnectContext } from "../hooks/web3ConnectContext";

const Header = () => {
  const { status, connect, account } = useMetaMask();
  const { accountBalance } = useWeb3ConnectContext();

  const walletConnect = () => {
    connect();
  };

  return (
    <div className="header container">
      <Navbar bg="dark" expand="lg" className={"header-main"}>
        <NavLink className={"header-logo"} to={"/"}>
          <img src={logo} alt="" />
        </NavLink>
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll">
          <Nav className="me-auto my-2 my-lg-0" style={{ maxHeight: "100px" }} navbarScroll>
            <NavLink to={`/dashboard`}>Dashboard</NavLink>
            <NavLink to={`/trade`}>Trade</NavLink>
            <NavLink to={`/swap`}>Swap</NavLink>
            <NavLink to={`/earn`}>Earn</NavLink>
            <NavLink to={`/liquidity`}>Liquidity</NavLink>
            {/*<NavLink to={`/auction`}>Auction</NavLink>*/}
            {/*<NavLink to={`/dao`}>DAO</NavLink>*/}
            {/*<NavLink to={`/analytics`}>Analytics</NavLink>*/}
            {/*<NavLink to={`/leaderboard`}>Leaderboard</NavLink>*/}
          </Nav>

          {status === "connected" ? (
            <div className="d-flex align-items-center w-details">
              <span className="w-balance">
                <img src={wallet} height={18} width={22} />
                {parseFloat(Number(accountBalance).toFixed(4))} KAVA
              </span>
              {/*<NavLink to={`/profile`}>*/}
                <span className="w-address">
                  <img src={person} height={16} width={16} />
                  {`${account?.slice(0, 5)}...${account?.slice(-5)}`}
                </span>
              {/*</NavLink>*/}
            </div>
          ) : status === "connecting" || status === "initializing" ? (
            <div className="d-flex align-items-center justify-content-around w-connect w-auto pointer-cursor">
              <span className="w-address">initializing...</span>
            </div>
          ) : status === "notConnected" ? (
            <div
              className="d-flex align-items-center justify-content-around w-connect w-auto  pointer-cursor"
              onClick={walletConnect}
            >
              <span className="w-address">Connect</span>
            </div>
          ) : null}
        </Navbar.Collapse>
      </Navbar>
    </div>
  );
};

export default Header;
