import { useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import { useWeb3ConnectContext } from "../../../hooks/web3ConnectContext";
import { useCommonStateContext } from "../../../hooks/commonStateContext";

import kava from "../../../assets/images/Kava-Coin.png";

import PlButton from "../../../components/buttons/Button";
import WithTag from "../../../components/inputFields/WithTag";
import { PercentageButtons } from "../../../components/buttons/Percentage";
import { SlippageRow } from "../../../components/SlippageRow";

import { addLampLiquidity, calcKavaAmountOut } from "../../../io/kava";

import { handleError } from "../../../components/toasts/Error";
import { waitingToast } from "../../../components/toasts/Waiting";
import { successToast } from "../../../components/toasts/Success";

const Deposit = ({ kavaPoolDetails, setKavaPoolDetails }) => {
  const { accountBalance } = useWeb3ConnectContext();
  const { setLoading } = useCommonStateContext();
  const [amountIn, setAmountIn] = useState(""); //user input the amount to add liquidity
  const [amountOut, setAmountOut] = useState(""); //user get tranche amount according amountIn and selected token
  const [minimumReceived, setMinimumReceived] = useState(""); // user received amount according amountOut and slippage

  useEffect(() => {
    countAmountOut();
  }, [amountIn]);


  const countAmountOut = async () => {
    try {
      let calLpAmount;
      try {
        calLpAmount = await calcKavaAmountOut(
          "100",
          amountIn,
        );
        console.log('calLpAmount-',calLpAmount);
      } catch (e) {
        console.error("error--", e);
      }

      if (Number(calLpAmount) !== 0) {
        setAmountOut(Number(calLpAmount));
      } else {
        setAmountOut("");
      }
    } catch (e) {
      setAmountOut("");
    }

  };

  const handleDeposit = async () => {
    try {
      setLoading(true);
      waitingToast(`Depositing ${amountOut} KAVA`);
      const res = await addLampLiquidity(amountOut, "5");
      successToast(`${amountOut} Deposited.`);
    } catch (e) {
      handleError(e);
    }
    setLoading(false);
  };

  return (
    <>
      <Col className="mt-2">
        <div className="shadowed-box card-main-content">
          <div className="card-title mb-3">
            <h3>DEPOSIT</h3>
          </div>
          <div className="mt-4">
            <WithTag
              label={"From"}
              placeholder={"0.0"}
              inputValue={amountIn}
              onChange={(e) => setAmountIn(e.target.value)}
              tag={
                <div className="d-flex align-items-center gap-1">
                  <img src={kava} height={14} width={14} /> KAVA
                </div>
              }
            />
            <div className="d-flex justify-content-between align-items-center mt-2">
              <span className="balance">Balance : {parseFloat(Number(accountBalance).toFixed(4))} KAVA</span>
              <PercentageButtons balance={Number(accountBalance)} setAmountIn={setAmountIn} />
            </div>
          </div>
          <div className="mt-4">
            <WithTag
              label={"To"}
              placeholder={"0.0"}
              inputClass={"cursor_not_allowed profit"}
              inputValue={amountOut}
              tag={
                <div className="d-flex align-items-center gap-1">
                  <div className="lamp_tag eth_lamp"></div>
                  <img src={kava} className="trade_head_img" height={12} width={12} /> LAMP/KAVA
                </div>
              }
            />
          </div>

          <div className="mt-4">
            <p className="d-flex justify-content-between align-items-center mb-1">
              Est. Allocated LAMP <span className="fw-600">XX</span>
            </p>
            <p className="d-flex justify-content-between align-items-center mb-1">
              Est. Allocated ETH <span className="fw-600">XX</span>
            </p>
            <p className="d-flex justify-content-between align-items-center mb-1">
              Minimum Received <span className="fw-600">
               {Number(minimumReceived) > 0
                 ? `${parseFloat(minimumReceived?.toFixed(5))} LAMP/KAVA`
                 : "-"}
            </span>
            </p>
            <p className="d-flex justify-content-between align-items-center mb-1">
              Est. Daily Income <span className="fw-600">XX LAMP</span>
            </p>
            <p className="d-flex justify-content-between align-items-center mb-1">
              Price Impact <span className="fw-600">XX%</span>
            </p>
            <SlippageRow allDetails={kavaPoolDetails} setAllDetails={setKavaPoolDetails} />
          </div>

          <div className="mt-4">
            <PlButton
              disabled={
                Number(amountIn) <= 0 || Number(amountIn) > Number(accountBalance)
              }
              label={Number(amountIn) <= 0
                ? "Enter an amount"
                : Number(amountIn) > Number(accountBalance)
                  ? "Insufficient funds"
                  : "DEPOSIT"}
              onClick={handleDeposit} />
          </div>
        </div>
      </Col>
    </>
  );
};

export default Deposit;
