// all the business logic will go here in container
// the page will be passed with all the props needed inside

import { useEffect, useState } from "react";
import { useMetaMask } from "metamask-react";
import { useCommonStateContext } from "../../hooks/commonStateContext";

import Page from "./Page";
import Loader from "../../components/loader/Loader";

import btcoinIcon from "../../assets/images/bitcoin.png";
import tetherIcon from "../../assets/images/usdt-coin.png";
import ethIcon from "../../assets/images/ethereum-coin.svg";
import kavaIcon from "../../assets/images/Kava-Coin.png";

import {
  getAllTokenPrices,
  getAssetDetails,
  getTranchesDetails,
  trancheSupply,
} from "../../io/kava";
import { useWeb3ConnectContext } from "../../hooks/web3ConnectContext";

import { ReactComponent as InfoTag } from "../../assets/images/info_outline.svg";
import { toolTipData } from "../../components/static/ToolTipData";

const lampEthData = [
  {
    title: "LAMP/KAVA",
    value: "1 LAMP = 500 KAVA",
    earnTitle: "Earn",
    earnValue: "100 LAMP",
    aprTitle: "APR",
    aprValue: "210.54%",
    totalDeposited: "Total Deposited",
    totalDepositedValue: "$28,018.42",
  },
];

const tradingTrancheData = [
  {
    title: "Pool 1",
    risk: "Low Risk",
    classname: "risk lowRisk",
    type: "senior",
    value: "Senior Tranche",
    earnValue: "100 LAMP",
    aprValue: "210.54%",
  },
  {
    title: "Pool 2",
    risk: "Moderate Risk",
    classname: "risk moderateRisk",
    type: "mezzanine",
    value: "Mezzanine Tranche",
    earnValue: "100 LAMP",
    aprValue: "210.54%",
  },
  {
    title: "Pool 3",
    risk: "High Risk",
    classname: "risk highRisk",
    type: "junior",
    value: "Junior Tranche",
    earnValue: "100 LAMP",
    aprValue: "210.54%",
  },
];

const tokenData = {
  columnTitle: [
    { title: "Token", className: "" },
    { title: "Price", className: "" },
    { title: "Amount", className: "" },
    { title: "Value", className: "" },
    {
      title: <span className="d-flex align-items-center gap-1">Weight/Target <InfoTag
        className="info_icon" data-tip={toolTipData.targetWeight} /></span>,
      className: "ps-0",
    },
    {
      title: <span className="d-flex align-items-center justify-content-center gap-1">Utilization <InfoTag
        className="info_icon" data-tip={toolTipData.utilization} /></span>, className: "ps-0 text-center",
    },
  ],
  columnData: [
    { token: "KAVA", image: kavaIcon },
    { token: "BTC", image: btcoinIcon },
    { token: "USDT", image: tetherIcon },
    { token: "ETH", image: ethIcon },
  ],
};

const Container = () => {
  const { account, chainId } = useMetaMask();
  const { setLoading, loading } = useCommonStateContext();
  const { accountBalance } = useWeb3ConnectContext();
  const [LampEthDetails, setLampEthDetails] = useState(); // will fetch after get integration details from doc
  const [tranchesDetails, setTranchesDetails] = useState([]);
  const [tokensDetails, setTokensDetails] = useState([]);

  useEffect(() => {
    if (accountBalance !== undefined) {
      fetchTrancheAndTokenData();
    }
  }, [accountBalance, account, chainId]);

  const fetchTrancheAndTokenData = async () => {
    setLoading(true);
    const prices = await getAllTokenPrices();
    console.log('prices-',prices);
    // await getkavaLiquidity()
    const tranchesData = await getTranchesDetails(tradingTrancheData);
    setTranchesDetails(tranchesData);


    let totalValueLocked = await trancheSupply();
    console.log('totalValueLocked-',totalValueLocked);
    const tokensData = await getAssetDetails(tokenData.columnData,prices, totalValueLocked);
    setTokensDetails(tokensData);
    console.log('tokensData-',tokensData);
    setLoading(false);
  };

  return (
    <div className="swap container">
      <Loader loading={loading} />
      <Page
        loading={loading}
        lampEthData={lampEthData}
        tokenData={tokenData}
        tokensDetails={tokensDetails}
        tranchesDetails={tranchesDetails}
      />
    </div>
  );
};

export default Container;
