import { Col } from "react-bootstrap";

import tether from "../../assets/images/usdt-coin.png";

import PlButton from "../../components/buttons/Button";
import WithTag from "../../components/inputFields/WithTag";
import { useState } from "react";
import { lvlRedeem, lvlStake } from "../../io/kava";
import { PercentageButtons } from "../../components/buttons/Percentage";
import { waitingToast } from "../../components/toasts/Waiting";
import { successToast } from "../../components/toasts/Success";
import { handleError } from "../../components/toasts/Error";
import { useCommonStateContext } from "../../hooks/commonStateContext";

const RedeemCardsDetail = ({ balances, account, approvedBalance, handleLgoApprove }) => {
  const [redeemAmountIn, setRedeemAmountIn] = useState();
  const { setLoading, setExplorerURL, setShowConfirmModal } = useCommonStateContext();

  const handleRedeem = async () => {
    setLoading(true);
    try {
      waitingToast(`Staking ${redeemAmountIn} Lamp`);

      const res = await lvlRedeem(account, redeemAmountIn);

      successToast(`${redeemAmountIn} Lamp Staked!`);

      //This URL needs to be updated according to the Kava Explorer.
      setExplorerURL(`https://goerli.etherscan.io/tx/${res.hash}`);
      // setSuccessMessage(`${redeemAmountIn} Lamp Staked!`)
      setShowConfirmModal(true);
    } catch (e) {
      handleError(e);
      setLoading(false);
    }
  };

  return (
    <>
      <Col className="mt-2">
        <div className="shadowed-box card-main-content">
          <div className="card-title mb-3">
            <h3>REDEEM LAMP</h3>
          </div>
          <div className="mt-4">
            <WithTag
              label={"Amount"}
              placeholder={"0.0"}
              tag={
                <div className="d-flex align-items-center gap-1">
                  <div className="lamp_tag eth_lamp"></div>
                  LGO
                </div>
              }
              inputValue={redeemAmountIn}
              onChange={(e) => setRedeemAmountIn(e.target.value)}
            />
            <div className="d-flex justify-content-between align-items-center mt-2">
              <span className="balance">Balance : {balances.redeem} LGO</span>
              <PercentageButtons setAmountIn={setRedeemAmountIn} balance={balances.redeem} />
            </div>
          </div>
          <div className="mt-4">
            <WithTag
              label={"Receive"}
              placeholder={"0.0"}
              disabled={true}
              tag={
                <div className="d-flex align-items-center gap-1">
                  <div className="lamp_tag eth_lamp"></div>
                  <img src={tether} className="trade_head_img" height={12} width={12} /> LAMP/USDT LP
                </div>
              }
            />
          </div>
          <div className="modal-confirm-btn mt-4">
            <PlButton
              disabled={Number(redeemAmountIn) === 0 || balances.redeem < Number(redeemAmountIn)}
              onClick={approvedBalance.lgo < Number(redeemAmountIn) ? handleLgoApprove : handleRedeem}
              label={
                Number(redeemAmountIn) === 0
                  ? "Enter an amount"
                  : balances.redeem < Number(redeemAmountIn)
                  ? "insufficient funds"
                  : approvedBalance.lgo < Number(redeemAmountIn)
                  ? "approve"
                  : "redeem"
              }
            />
          </div>
        </div>
      </Col>
    </>
  );
};

export default RedeemCardsDetail;
