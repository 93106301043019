const DefaultInput = ({ label, disabled, placeholder, inputValue, onChange, className }) => {
  return (
    <>
      <div className={`input-wrapper ${className} ${disabled && "disabled"}`}>
        <label>{label}</label>
        <input
          type="number"
          onKeyPress={(event) => {
            if (event.charCode < 48 && event.charCode !== 46) {
              event.preventDefault();
            }
          }}
          min="0"
          placeholder={placeholder}
          value={inputValue}
          onChange={onChange}
          disabled={disabled}
        />
      </div>
    </>
  );
};

export default DefaultInput;
