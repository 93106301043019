import { Col, Row } from "react-bootstrap";

import PlaceOrder from "./cards/PlaceOrder";
import TradeDetail from "../../components/TradeDetail";
import TradingViewChart from "./cards/TradingViewChart";
import TokenPairActivity from "./cards/TokenPairActivity";

const Page = ({
  tradingDetails,
  tradeConditions,
  setTradeConditions,
  amountIn,
  setAmountIn,
  leverage,
  setLeverage,
}) => {
  return (
    <>
      <Row>
        <Col md={8} className="first_column">
          <TokenPairActivity
            tradeConditions={tradeConditions}
            setTradeConditions={setTradeConditions}
            tradingDetails={tradingDetails}
          />
          <TradingViewChart tradeConditions={tradeConditions} />
          <TradeDetail />
        </Col>
        <Col className="second_column" md={4}>
          <PlaceOrder
            tradeConditions={tradeConditions}
            setTradeConditions={setTradeConditions}
            amountIn={amountIn}
            setAmountIn={setAmountIn}
            leverage={leverage}
            setLeverage={setLeverage}
          />
        </Col>
      </Row>
    </>
  );
};

export default Page;
