// all the business logic will go here in container
// the page will be passed with all the props needed inside

import { useEffect, useState } from "react";
import { useMetaMask } from "metamask-react";
import Page from "./Page";
import Loader from "../../components/loader/Loader";
import { useParams } from "react-router-dom";
import { useCommonStateContext } from "../../hooks/commonStateContext";
import configs from "../../config.json";

import { ReactComponent as TotalPercentag } from "../../assets/images/totalPercentag.svg";
import { ReactComponent as TotalDolarIcon } from "../../assets/images/totaldolarIcon.svg";
import { ReactComponent as WalletIcon } from "../../assets/images/walletIcon.svg";
import { ReactComponent as StakingIcon } from "../../assets/images/stakingBalanceIcon.svg";

import { supportedTokens, tokenImages } from "../../components/static/SupportedTokens";
import { slippageOptions } from "../../components/static/SlippageOptions";
import {
  balanceOf,
  getAllTokenPrices,
  getLiquidity,
  getTokenPrice,
  getTokensDetails,
  getTrancheData,
  trancheSupply,
} from "../../io/kava";

import { useWeb3ConnectContext } from "../../hooks/web3ConnectContext";
import { toolTipData } from "../../components/static/ToolTipData";
import { ReactComponent as InfoTag } from "../../assets/images/info_outline.svg";

const tradingTranchesData = [
  {
    title: "Pool 1",
    risk: "Low Risk",
    classname: "risk lowRisk",
    type: "senior",
  },
  {
    title: "Pool 2",
    risk: "Moderate Risk",
    classname: "risk moderateRisk",
    type: "mezzanine",
  },
  {
    title: "Pool 3",
    risk: "High Risk",
    classname: "risk highRisk",
    type: "junior",
  },
];

const trancheStatusData = {
  columnTitle: [
    { title: "Asset", className: "" },
    { title: "Amount", className: "" },
    { title: "Value", className: "" },
    { title: "Utilization", className: "ps-0 text-center" },
    { title: "Fees", className: "ps-0 text-center" },
  ],
  columnData: [
    { token: "KAVA", image: tokenImages.KAVA.image },
    { token: "BTC", image: tokenImages.BTC.image },
    { token: "USDT", image: tokenImages.USDT.image },
    { token: "ETH", image: tokenImages.ETH.image },
  ],
};

const Container = () => {
  // will fetch below data after get integration details from doc
  const [metricsDetails, setMetricsDetails] = useState();

  const [isSwitchOn, setIsSwitchOn] = useState(false); // to switch balances between staking and wallet
  const [trancheConditions, setTrancheConditions] = useState({
    selectedToken: supportedTokens[0],
    slippage: slippageOptions[0],
    selectedTrancheTab: "buy",
  });
  const { name: trancheType } = useParams();
  const { account, chainId } = useMetaMask();
  const { loading, setLoading } = useCommonStateContext();
  const { accountBalance } = useWeb3ConnectContext();

  const trancheMetricsData = [
    {
      title: <p className="d-flex align-items-center gap-1">Assets Under Management<InfoTag
        className="info_icon tranche_info" data-tip={toolTipData.poolLiquidity} /></p>,
      value: `$${Math.round(trancheConditions.totalDeposited).toLocaleString()}`,
      img: <TotalDolarIcon />,
      desc: `${parseFloat(trancheConditions?.llpSupply?.toFixed(3))} ${
        trancheType === "senior" ? "Senior" : trancheType === "junior" ? "Junior" : "Mezzanine"
      } LLP`,
    },
    {
      title: <p className="d-flex align-items-center gap-1">APR <InfoTag
        className="info_icon tranche_info" data-tip={toolTipData.trancheApr} /></p>,
      value: "210.54%", img: <TotalPercentag />, desc: "Fee 180.30% + LAMP 30.24%",
    },
    {
      title: "Wallet Balance",
      value: `${parseFloat(trancheConditions.trancheBalance).toFixed(2)} `,
      label: `${trancheType === "senior" ? "Senior" : trancheType === "junior" ? "Junior" : "Mezzanine"} LLP`,
      img: <WalletIcon />,
      tag: "Deposit",
    },
    {
      title: "Staking Balance",
      value: `${parseFloat(trancheConditions.stakingBalance).toFixed(2)} `,
      label: `${trancheType === "senior" ? "Senior" : trancheType === "junior" ? "Junior" : "Mezzanine"} LLP`,
      img: <StakingIcon />,
      tag: "Withdraw",
    },
  ];

  useEffect(() => {
    if (accountBalance !== undefined) {
      fetchTrancheConditions();
    }
  }, [
    isSwitchOn,
    accountBalance,
    account,
    chainId,
    trancheConditions.selectedToken,
    trancheType,
    trancheConditions.selectedTrancheTab,
  ]);

  const fetchTrancheConditions = async () => {
    setLoading(true);
    const totalValueLocked = await trancheSupply();
    const prices = await getAllTokenPrices();
    const assetData = await getTokensDetails(
      trancheStatusData.columnData,
      prices,
      configs.tranches[trancheType],
    );

    const tokenAmount = await getLiquidity(trancheConditions.selectedToken.value);
    const tokenPrice = await getTokenPrice(trancheConditions.selectedToken.value);
    console.log('tokenAmount--',tokenAmount);
    // const balance = trancheConditions.selectedToken.value === "KAVA" ? accountBalance : await balanceOf(trancheConditions.selectedToken.value, account);
    const balance = await balanceOf(trancheConditions.selectedToken.value, account);
    console.log('$$$$$$$$$$');
    const trancheData = await getTrancheData(trancheConditions.selectedToken.value, trancheType, isSwitchOn ? configs.trade.levelMaster : configs.trade.pool, account);
    console.log('dddd',trancheData);
    setTrancheConditions({
      ...trancheConditions,
      ...trancheData,
      totalDeposited: Number(trancheData.total),
      tranchePrice: Number(trancheData.total) / trancheData.llpSupply,
      tokenBalance: Number(balance),
      assetDetails: assetData,
      weightValue: (Number(tokenAmount.poolAmount) * Number(tokenPrice)) / Number(totalValueLocked) * 100,
    });

    setLoading(false);
  };

  return (
    <div className="swap liquidity-details-main container">
      <Loader loading={loading} />
      <Page
        trancheMetricsData={trancheMetricsData}
        trancheStatusData={trancheStatusData}
        tradingTranchesData={tradingTranchesData}
        trancheType={trancheType}
        trancheConditions={trancheConditions}
        setTrancheConditions={setTrancheConditions}
        isSwitchOn={isSwitchOn}
        setIsSwitchOn={setIsSwitchOn}
      />
    </div>
  );
};

export default Container;
