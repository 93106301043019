import Modal from "react-bootstrap/Modal";

import closeIcon from "../../../assets/images/modal-close-icon.svg";

import PlButton from "../../../components/buttons/Button";

const Claim = (props) => {
  const { show: data, onHide, handleClaim } = props;
  console.log("data=", data);
  return (
    <Modal {...props} size="lg" centered dialogClassName="claim-modal" show={data}>
      <Modal.Body>
        <div className="close-icon" onClick={onHide}>
          <img src={closeIcon} />
        </div>

        <div className="modal-title mb-3">
          <h3>CLAIM</h3>
        </div>
        <p>Claimable Rewards : {parseFloat(Number(data.claimAmount).toFixed(5))} LAMP</p>
        <div className="mt-4"></div>

        <div className="modal-confirm-btn mt-4">
          <PlButton
            label={"Claim"}
            onClick={() => {
              handleClaim(data.type);
              onHide();
            }}
            disabled={Number(data.claimAmount) === 0} />
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default Claim;
