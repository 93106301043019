import React, { useEffect, useRef, useState } from "react";
import { Col, Row, Tab, Tabs } from "react-bootstrap";
import arrowSvg from "../assets/images/arrow-forward.svg";
import arrow from "../assets/images/launch_redirect.svg";
import { useCommonStateContext } from "../hooks/commonStateContext";

import { cancelOrder, getSwapAmount, getTokenPrice } from "../io/kava";

import Confirm from "./modals/Confirm";
import CompleteTransaction from "./modals/CompleteTransaction";
import { tokenImages } from "./static/SupportedTokens";
import configs from "../config.json";
import { ethers } from "ethers";
import { useLazyQuery } from "@apollo/client";
import { GET_HISTORIES } from "../io/subgraph";
import { useMetaMask } from "metamask-react";
import { waitingToast } from "./toasts/Waiting";
import { successToast } from "./toasts/Success";
import { handleError } from "./toasts/Error";
import loader from "../assets/images/loader.gif";
import { formatEther, formatUnits } from "ethers/lib.esm/utils";
import { useWeb3ConnectContext } from "../hooks/web3ConnectContext";

const orderColumnTitles = ["From", "To", "Trigger Price", "Market Price", "Action"];
const historyColumnTitles = ["Action", "Condition", "Type", "Status",""];

const SwapDetail = () => {
  const { chainId, account } = useMetaMask();
  const {accountBalance} = useWeb3ConnectContext()
  const [dataFetching, setDataFetching] = useState(false);

  const [showModal, setShowModal] = useState(false); //to show and hide confirmation modal
  const [selectedOrder, setSelectedOrder] = useState(); //to select order which is user wants to cancel
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [orderDetails, setOrderDetails] = useState({}); //to set all orders from subgraph

  const { handleCloseSuccessModal, setUsdValue, setLoading, setExplorerURL } = useCommonStateContext();
  const [orderHistory] = useLazyQuery(GET_HISTORIES, {
    variables: { owner: account, skip: 0, updateType: "SWAP" },
  });

  useEffect(() => {
    if (accountBalance !== undefined) {
      getOrderHistoryData();
    }
  }, [account,accountBalance, chainId]);

  //for get data from subgraph to show history and active orders
  const getOrderHistoryData = async () => {
    setDataFetching(true);
    const { data } = await orderHistory();

    const unixTime = (time) => {
      var u = new Date(time * 1000);
      return u.toLocaleString();
    };

    let swapOrders = [];
    if (data.histories.length > 0) {
      for (let i = 0; i < data.histories.length; i++) {
        const newOrder = {
          ...data.histories[i],
          assetIn: configs.tokensType[data.histories[i].tokenIn],
          assetOut: configs.tokensType[data.histories[i].tokenOut],
          dateTime: unixTime(data.histories[i].createdAtTimestamp),
          priceOut: ethers.utils.formatEther(
            data.histories[i].amountOut ? data.histories[i].amountOut : data.histories[i].minAmountOut,
          ),
        };

        newOrder.priceIn = formatUnits(data.histories[i].amountIn, configs.decimals[newOrder.assetIn]);

        if (newOrder.type === "LIMIT") {
          if (newOrder.status === "OPEN") {
            newOrder.priceTriggered = ethers.utils.formatUnits(newOrder.triggerPrice, 12);
          } else {
            newOrder.priceTriggered = ethers.utils.formatUnits(newOrder.executionPrice, 12);
          }
        }

        swapOrders.push(newOrder);
      }
    }

    let limitSwapOrders = [];
    if (data.orders.length > 0) {
      for (let i = 0; i < data.orders.length; i++) {
        if (data.orders[i].status === "OPEN") {
          const ID = data.orders[i].id.split("-")[0];
          const newOrder = {
            ...data.orders[i],
            assetIn: configs.tokensType[data.orders[i].tokenIn],
            assetOut: configs.tokensType[data.orders[i].tokenOut],
            priceOut: ethers.utils.formatEther(data.orders[i].minAmountOut),
            orderId: Number(ID),
            priceTriggered: formatUnits(data.orders[i].price, 12),
          };

          newOrder.priceIn = formatUnits(data.orders[i].amountIn, configs.decimals[newOrder.assetIn]);

          try {
            newOrder.marketPrice = await getSwapAmount(newOrder.assetIn, newOrder.assetOut, 1);
          } catch (e) {
            const priceIn = await getTokenPrice(newOrder.assetIn);
            const priceOut = await getTokenPrice(newOrder.assetOut);
            newOrder.marketPrice = Number(priceIn) / Number(priceOut);
          }

          limitSwapOrders.push(newOrder);
        }
      }
    }

    limitSwapOrders = limitSwapOrders.length > 0 ? limitSwapOrders.sort((a, b) => b.orderId - a.orderId) : limitSwapOrders;
    swapOrders = swapOrders.length > 0 ? swapOrders.sort((a, b) => b.createdAtTimestamp - a.createdAtTimestamp) : swapOrders;
    setOrderDetails({
      active: limitSwapOrders,
      history: swapOrders,
    });

    setDataFetching(false);
  };

  //for cancel active orders
  const handleCancelOrder = async (order) => {
    setLoading(true);
    try {
      waitingToast(
        `Cancelling order to swap ${order.priceIn} ${order.assetIn} for ${parseFloat(
          Number(order.priceOut).toFixed(2),
        )} ${order.assetOut}`,
      );

      const res = await cancelOrder(order);

      successToast(
        ` Cancelled order to swap ${order.priceIn} ${order.assetIn} for ${parseFloat(
          Number(order.priceOut).toFixed(2),
        )} ${order.assetOut}`,
      );

      //This URL needs to be updated according to the Kava Explorer.
      setExplorerURL(`https://goerli.etherscan.io/tx/${res.hash}`);
      setShowCancelModal(true);
    } catch (e) {
      handleError(e);
    }
    setLoading(false);
  };

  const handleShowModal = async (order) => {
    setSelectedOrder(order);
    setShowModal(true);

    const fromPrice = await getTokenPrice(order.assetIn);
    const toPrice = await getTokenPrice(order.assetOut);
    const getInValue = Number(fromPrice) * Number(order.priceIn);
    const getOutValue = Number(toPrice) * Number(order.priceOut);

    setUsdValue({
      fromUsdValue: parseFloat(Number(getInValue).toFixed(2)),
      toUsdValue: parseFloat(Number(getOutValue).toFixed(2)),
    });
  };

  const handleCloseModal = () => {
    setSelectedOrder();
    setShowModal(false);
  };

  return (
    <div className="shadowed-box max_box  mt-4">
      {dataFetching ? (
        <div className="d-flex align-items-center justify-content-center trade_history_loader w-100">
          <img className="loading_img" src={loader} />
        </div>
      ) : (
        <Tabs defaultActiveKey="orders" id="uncontrolled-tab-example" className="mb-3 swap-details-tabs">
          <Tab eventKey="orders" title="Active Orders">
            <div className="trading-section history_table_section">
              <table className="w-100">
                {orderDetails.active?.length === 0 ? (
                  <div className="d-flex align-items-center justify-content-center empty_div w-100">
                    <span>You haven't placed any orders yet.</span>
                  </div>
                ) : (
                  <>
                    <tr className="heading swap-content-wrapper mt-4">
                      {orderColumnTitles.map((column, i) => (
                        <th>{column}</th>
                      ))}
                    </tr>
                    <tbody className="swap-order-wrapper gap-3 align-content-start">
                    {orderDetails.active?.map((order, i) => (
                      <tr className="swap-data data mb-0" key={i}>
                        <td>
                          <div className="d-flex align-items-center gap-1">
                            <img src={tokenImages[order.assetIn].image} height={18} width={18} />
                            <span>{parseFloat(Number(order.priceIn).toFixed(2))}</span>
                            <span>{order?.assetIn}</span>
                          </div>
                        </td>
                        <td>
                          <div className="d-flex align-items-center gap-1">
                            <img src={tokenImages[order.assetOut].image} height={18} width={18} />
                            <span>{parseFloat(Number(order.priceOut).toFixed(5))}</span>
                            <span>{order?.assetOut}</span>
                          </div>
                        </td>
                        <td>
                            <span>
                              {`1 ${order?.assetIn} ≥ ${parseFloat(Number(order.priceTriggered).toFixed(5))} ${order?.assetOut}`}
                            </span>
                        </td>
                        <td>
                            <span>
                              {`1 ${order?.assetIn} = ${parseFloat(Number(order.marketPrice).toFixed(5))} ${
                                order?.assetOut
                              }`}
                            </span>
                        </td>
                        <td>
                            <span
                              className="cancel_text"
                              onClick={() => {
                                handleShowModal(order);
                              }}
                            >
                              Cancel
                            </span>
                        </td>
                      </tr>
                    ))}
                    </tbody>
                  </>
                )}
              </table>
            </div>
          </Tab>
          <Tab eventKey="history" title="History">
            <div className="trading-section history_table_section">
              <table className="w-100">
                {orderDetails.history?.length === 0 ? (
                  <div className="d-flex align-items-center justify-content-center empty_div w-100">
                    <span>No records found.</span>
                  </div>
                ) : (
                  <>
                    <tr className="heading swap-content-wrapper mt-4">
                      {historyColumnTitles.map((column, i) => (
                        <th>{column}</th>
                      ))}
                    </tr>
                    <tbody className="swap-order-wrapper">
                    {orderDetails.history?.map((order, i) => (
                      <tr className="swap-data data mb-0 history-data" key={i}>
                        <td>
                          <div className="d-flex align-items-center">
                            <span>{parseFloat(Number(order.priceIn).toFixed(2))}</span>
                            <span className="bold mx-2">{order?.assetIn} </span>
                            {/*<img src={tokenImages[order?.assetIn]?.image} height={16} width={16} />*/}
                            <img className="mx-2" src={arrowSvg} height={10} width={14} />
                            <span>{parseFloat(Number(order.priceOut).toFixed(5))}</span>
                            <span className="bold mx-2">{order?.assetOut} </span>
                            {/*<img src={tokenImages[order?.assetOut]?.image} height={16} width={16} />*/}
                          </div>
                          <span className="timeStamp">{order?.dateTime}</span>
                        </td>
                        <td>
                            <span>
                              {order?.type === "LIMIT"
                                ? `1 ${order?.assetIn} ${
                                  order.status === "FILLED" ? "=" : "≥"
                                } ${parseFloat(Number(order.priceTriggered).toFixed(5))} ${order?.assetOut}`
                                : "-"}
                            </span>
                        </td>
                        <td>
                          <span>{order?.type}</span>
                        </td>
                        <td className="pe-2">
                            <span
                              className={`${
                                order.status === "FILLED"
                                  ? "trade-change-value"
                                  : order.status === "CANCELLED"
                                  ? "loss"
                                  : ""
                              }`}
                            >
                              {order.status === "FILLED"
                                ? "Executed"
                                : order.status === "CANCELLED"
                                  ? "Cancelled"
                                  : "Created"}
                            </span>
                        </td>
                        <td>
                          <a target="_blank" href={`https://explorer.kava.io/tx/${order.tx}`}>
                            <img src={arrow} height={12} width={12} className="pointer-cursor" />
                          </a>
                        </td>
                      </tr>
                    ))}
                    </tbody>
                  </>
                )}
              </table>
            </div>
          </Tab>
        </Tabs>
      )}

      {showModal && (
        <Confirm
          setShowModal={setShowModal}
          title={`CANCEL SWAP ${selectedOrder?.assetIn}/${selectedOrder?.assetOut}`}
          selectedOrder={selectedOrder}
          show={showModal}
          onHide={handleCloseModal}
          handleCancelOrder={handleCancelOrder}
        />
      )}
      {showCancelModal && (
        <CompleteTransaction
          selectedOrder={selectedOrder}
          show={showCancelModal}
          onHide={() => {
            handleCloseSuccessModal();
            setSelectedOrder();
          }}
        />
      )}
    </div>
  );
};

export default SwapDetail;
