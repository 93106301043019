import React, { useEffect, useState } from "react";
import { useLazyQuery } from "@apollo/client";
import { useMetaMask } from "metamask-react";
import { ethers } from "ethers";
import { Tab, Tabs } from "react-bootstrap";
import configs from "../config.json";
import loader from "../assets/images/loader.gif";

import { GET_TRADE_HISTORIES, GET_TRADE_POSITIONS } from "../io/subgraph";
import {
  fees,
  getLiquidity,
  getMmRatio,
  getOrderData,
  getTokenPrice, positionIndex,
} from "../io/kava";

import CompleteTransaction from "./modals/CompleteTransaction";

import HistoryTab from "./tradeTabs/HistoryTab";
import OrdersTab from "./tradeTabs/OrdersTab";
import PositionsTab from "./tradeTabs/PositionsTab";
import { formatEther, formatUnits } from "ethers/lib.esm/utils";
import { useWeb3ConnectContext } from "../hooks/web3ConnectContext";

const TradeDetail = () => {
  const { chainId, account } = useMetaMask();
  const { accountBalance } = useWeb3ConnectContext();

  const [orderMessage, setOrderMessage] = useState("");
  const [dataFetching, setDataFetching] = useState(false);
  const [completeTransactionModal, setCompleteTransactionModal] = useState(false);

  //to set all trade orders from subgraph
  const [tableData, setTableData] = useState({});

  const [orderHistory] = useLazyQuery(GET_TRADE_HISTORIES, {
    variables: { owner: account, skip: 0 },
  });
  const [positions] = useLazyQuery(GET_TRADE_POSITIONS, {
    variables: { owner: account },
    context: { clientName: "orders" },
  });

  useEffect(() => {
    if (accountBalance !== undefined) {
      fetchOrderDetails();
    }
  }, [account, accountBalance, chainId]);

  const fetchOrderDetails = async () => {
    setDataFetching(true);
    const { data } = await orderHistory();
    const { data: positionData } = await positions();
    const unixTime = (time) => {
      var u = new Date(time * 1000);
      return u.toLocaleString();
    };

    let history = [];
    data?.histories?.map((order) => {
      if (order.updateType !== "SWAP") {
        const ID = order.id.split("-")[0];
        const getIndexTokenDetails = positionData?.perpOrders.find((o) => Number(o.id) === Number(ID));
        let updatedOrder = {
          ...order,
          collateralAsset: configs.tokensType[order.collateralToken],
          priceTrigger: formatEther(order.triggerPrice),
          sizeChangeValue: formatUnits(order.size, 30),
          collateral: formatUnits(order.collateralValue, 30),
          dateTime: unixTime(order.createdAtTimestamp),
        };

        if (getIndexTokenDetails) {
          updatedOrder.indexAsset = configs.tokensType[getIndexTokenDetails.indexToken];
        } else {
          const openOrder = history?.find((o) => o.id.split("-")[0] === order.id.split("-")[0]);
          updatedOrder.indexAsset = openOrder.indexAsset;
        }

        history.push(updatedOrder);
      }
    });

    let ordersData = [];
    for (let i = 0; i < data?.orders.length; i++) {
      if (data?.orders[i].updateType !== "SWAP" && data?.orders[i].status === "OPEN") {
        const ID = data?.orders[i].id;
        const details = await getOrderData(ID);

        const updatedOrder = {
          ...data?.orders[i],
          collateralAsset: configs.tokensType[data?.orders[i].collateralToken],
          indexAsset: configs.tokensType[details.indexToken.toLowerCase()],
          payAsset: configs.tokensType[data?.orders[i].payToken],
          priceTrigger: formatUnits(data?.orders[i].price, 12),
          sizeChangeValue: formatUnits(data?.orders[i]?.sizeChange, 30),
          marketPrice: await getTokenPrice(configs.tokensType[details.indexToken.toLowerCase()]),
        };
        ordersData.push(updatedOrder);
      }
    }

    let openPositions = [];
    for (let i = 0; i < data?.positions.length; i++) {
      if (data?.positions[i].status === "OPEN") {

        // const tokenAprice = await getTokenPrice(configs.tokensType[positionData?.perpOrders[i].payToken.substring(1)]);
        const getIndexTokenDetails = positionData?.positions.find((order) => order.id === data?.positions[i].id);
        const mmRatio = await getMmRatio();
        const fee = await fees();

        let updatedOrder = {
          ...data?.positions[i],
          collateralAsset: configs.tokensType[data?.positions[i].collateralToken],
          indexAsset: configs.tokensType[getIndexTokenDetails.indexToken],
          priceTrigger: formatUnits(data?.positions[i].entryPrice, 12),
          collateral: formatUnits(data?.positions[i].collateralValue, 30),
          sizeChangeValue: formatUnits(data?.positions[i].size, 30),
          marketPrice: await getTokenPrice(configs.tokensType[getIndexTokenDetails.indexToken]),
          pnl: formatUnits(data?.positions[i].realizedPnl, 30),
          leverage: formatUnits(data?.positions[i].leverage, 30),
          closeFee: Number(formatUnits(data?.positions[i].size, 30)) * Number(fee.positionFee),
          side: data?.positions[i].side === 0 || data?.positions[i].side === "LONG" ? 0 : 1,
        };
        const positionSizeOfToken = Number(updatedOrder.sizeChangeValue) / Number(updatedOrder.marketPrice);
        updatedOrder = {
          ...updatedOrder,
          estimatedPnl:
            updatedOrder.side === 0
              ? (Number(updatedOrder.marketPrice) - Number(updatedOrder.priceTrigger)) * (Number(updatedOrder.sizeChangeValue) / Number(updatedOrder.priceTrigger))
              : (Number(updatedOrder.priceTrigger) - Number(updatedOrder.marketPrice)) * (Number(updatedOrder.sizeChangeValue) / Number(updatedOrder.priceTrigger)),
          liquidationPrice:
            updatedOrder.side === 0
              ? Number(updatedOrder.marketPrice) -
              (Number(updatedOrder.collateral) - Number(updatedOrder.sizeChangeValue) * mmRatio) /
              ((1 - mmRatio) * positionSizeOfToken)
              : Number(updatedOrder.marketPrice) -
              (Number(updatedOrder.collateral) - Number(updatedOrder.sizeChangeValue) * mmRatio) /
              ((1 + mmRatio) * (positionSizeOfToken * -1)),
          borrowFee: await positionIndex(updatedOrder.id, updatedOrder.indexAsset),
        };

        openPositions.push({
          ...updatedOrder,
          netValue: Number(updatedOrder.collateral) + updatedOrder.estimatedPnl - updatedOrder.closeFee - updatedOrder.borrowFee,
          isProfit:
            (updatedOrder.side === 0 && Number(updatedOrder.marketPrice) >= Number(updatedOrder.priceTrigger)) ||
            (updatedOrder.side === 1 && Number(updatedOrder.priceTrigger) >= Number(updatedOrder.marketPrice)),
        });
      }
    }

    ordersData = ordersData.sort((a, b) => b.id - a.id);
    history = history.sort((a, b) => b.createdAtTimestamp - a.createdAtTimestamp);
    openPositions = openPositions.sort((a, b) => b.createdAtTimestamp - a.createdAtTimestamp);

    setTableData({
      allOrders: ordersData,
      allHistory: history,
      allPositions: openPositions,
    });
    setDataFetching(false);
  };

  return (
    <div className="shadowed-box order-details-wrappper">
      {dataFetching ? (
        <div className="d-flex align-items-center justify-content-center trade_history_loader w-100">
          <img className="loading_img" src={loader} />
        </div>
      ) : (
        <Tabs
          defaultActiveKey="positions"
          id="uncontrolled-tab-example"
          className="trade-details-tabs swap-details-tabs"
        >
          <Tab eventKey="positions" title="Positions">
            <PositionsTab tableData={tableData}
                          setCompleteTransactionModal={setCompleteTransactionModal}
                          setOrderMessage={setOrderMessage} />
          </Tab>

          <Tab eventKey="orders" title="Orders">
            <OrdersTab tableData={tableData}
                       setCompleteTransactionModal={setCompleteTransactionModal}
                       setOrderMessage={setOrderMessage} />
          </Tab>

          <Tab eventKey="history" title="History">
            <HistoryTab tableData={tableData} />
          </Tab>
        </Tabs>
      )}

      {completeTransactionModal && (
        <CompleteTransaction
          orderMessage={orderMessage}
          show={completeTransactionModal}
          successMessage={orderMessage}
          onHide={() => window.location.reload()}
        />
      )}

    </div>
  );
};

export default TradeDetail;
