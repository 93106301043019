// Will mostly have only UI component imports and placement in this file.

import { Card, Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import { ReactComponent as RotationIcon } from "../../../assets/images/rotation-icon.svg";
import arrowSvg from "../../../assets/images/arrow-forward.svg";
import kava from "../../../assets/images/Kava-Coin.png";

import PlButton from "../../../components/buttons/Button";

const LampEthTranche = ({ lampEthData }) => {
  const navigate = useNavigate();
  return (
    <>
      <Row>
        <div className="liquidity-title mb-4">
          <h3>LAMP/KAVA Liquidity Pool</h3>
        </div>
        {lampEthData?.map((item, id) => (
          <Col key={id}>
            <Card className="shadowed-box earn_card pointer-cursor"  onClick={() => navigate("/liquidity/lamp")}>
              <Card.Body className="liquidity-card-wrapper">
                <div className="d-flex justify-content-between aligh-center">
                  <div className="liquidity-card-content">
                    <h2 className="d-flex align-items-center">
                      <span className="d-flex align-items-center justify-content-center">
                        <span className="earn_tag"></span>
                        <img className="trade_list_imgs" height={22} width={22} src={kava} />
                      </span>
                      {item.title}
                    </h2>
                    <h6>
                      {item.value}
                      <span className="ms-2">
                        <RotationIcon />
                      </span>
                    </h6>
                  </div>
                  <div className="liquidity-card-content">
                    <p>{item.earnTitle}</p>
                    <span>
                      <span className="liquidity-value">{item.earnValue}</span>
                      <span className="ms-2">per day</span>
                    </span>
                  </div>
                  <div className="liquidity-card-content">
                    <p>{item.aprTitle}</p>
                    <span>{item.aprValue}</span>
                  </div>
                  <div className="liquidity-card-content">
                    <p>{item.totalDeposited}</p>
                    <span>{item.totalDepositedValue}</span>
                  </div>
                  {/*<div className="liquidity-card-content">*/}

                  {/*</div>*/}
                  <div className="liquidity-card-arrowIcon d-flex align-items-center">
                    <PlButton src={arrowSvg} className="sqaure" height={14} width={14} />
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </>
  );
};

export default LampEthTranche;
