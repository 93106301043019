import { useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { useCommonStateContext } from "../../../hooks/commonStateContext";

import SwapIcon from "../assets/swap-icon.svg";
import SwapLeftRightIcon from "../assets/swap-leftright-icon.svg";

import PlButton from "../../../components/buttons/Button";
import Confirm from "../../../components/modals/Confirm";
import CompleteTransaction from "../../../components/modals/CompleteTransaction";
import { supportedTokens } from "../../../components/static/SupportedTokens";
import { countSwapFee, getTokenPrice } from "../../../io/kava";
import { PercentageButtons } from "../../../components/buttons/Percentage";
import { SlippageRow } from "../../../components/SlippageRow";
import WithTagSelect from "../../../components/inputFields/WithTagSelect";

const PlaceOrder = ({
                      swapConditions,
                      setSwapConditions,
                      amountIn,
                      setAmountIn,
                      amountOut,
                      minimumReceived,
                      handleSwapValue,
                      handleApprove,
                      handleSwap,
                    }) => {
  const [swapConfirmModal, setSwapConfirmModal] = useState(false); //to hide and show swap confirmation modal
  const { showConfirmModal, handleCloseSuccessModal, setUsdValue } = useCommonStateContext();
  const [swapFee, setSwapFee] = useState(0);

  useEffect(() => {
    calcSwapFee();
  }, [swapConditions.selectedFrom, swapConditions.selectedTo, amountIn]);

  const calcSwapFee = async () => {
    try {
      const feeAmount = await countSwapFee(swapConditions.selectedFrom.value, swapConditions.selectedTo.value, amountIn);
      // console.log("feeAmount--", feeAmount);
      setSwapFee((Number(feeAmount) / amountIn * 100));
      // console.log("swapFee--", Number(feeAmount) / amountIn * 100);
    } catch (e) {
      setSwapFee(0);
    }
  };

  const handleShowModal = async () => {
    setSwapConfirmModal(true);

    let getFrom;
    let getTo;
    const fromPrice = await getTokenPrice(swapConditions.selectedFrom.value);
    const toPrice = await getTokenPrice(swapConditions.selectedTo.value);
    console.log("fromPrice--", fromPrice);
    console.log("toPrice--", toPrice);
    getFrom = Number(fromPrice) * Number(amountIn);
    getTo = Number(toPrice) * Number(amountOut);
    setUsdValue({
      fromUsdValue: parseFloat(Number(getFrom).toFixed(2)),
      toUsdValue: parseFloat(Number(getTo).toFixed(2)),
    });
  };

  const SwapButton = () => {
    console.log("amountOut--", Number(amountOut));
    return (
      <div className="mt-4">
        <PlButton
          disabled={swapConditions.selectedFrom?.value === swapConditions.selectedTo?.value ||
          Number(amountIn) <= 0 ||
          Number(amountOut) === 0 ||
          Number(amountOut) > Number(swapConditions.availableTokens) ||
          Number(swapConditions.fromBalance) < Number(amountIn)
          }
          label={swapConditions.selectedFrom?.value === swapConditions.selectedTo.value
            ? "no route for swap"
            : Number(amountIn) === 0
              ? "enter an amount"
              : (Number(amountOut) === 0 || Number(amountOut) > Number(swapConditions.availableTokens))
                ? "insufficient liquidity"
                : Number(swapConditions.fromBalance) < Number(amountIn)
                  ? "insufficient funds"
                  : swapConditions.approvedBalance < Number(amountIn)
                    ? "approve"
                    : "swap"
          }
          onClick={() => (swapConditions.approvedBalance < Number(amountIn) ? handleApprove() : handleShowModal(true))}
        />
      </div>
    );
  };

  return (
    <>
      <div className="shadowed-box">
        <Tabs
          defaultActiveKey="market"
          activeKey={swapConditions.selectedSwapTab}
          onSelect={(k) => setSwapConditions({ ...swapConditions, selectedSwapTab: k })}
          id="uncontrolled-tab-example"
          className="mb-3 swap_card_tabs"
        >
          <Tab eventKey="market" title="MARKET">
            <div className="mt-4">
              <FromInput
                swapConditions={swapConditions}
                setSwapConditions={setSwapConditions}
                amountIn={amountIn}
                setAmountIn={setAmountIn}
              />
              <div className="d-flex justify-content-between align-items-center mt-2">
                <span className="balance">
                  Balance : {parseFloat(swapConditions?.fromBalance?.toFixed(5))} {swapConditions.selectedFrom?.value}
                </span>
                <PercentageButtons balance={swapConditions.fromBalance} setAmountIn={setAmountIn} />
              </div>
            </div>
            <div className="d-flex justify-content-center align-items-center mt-3">
              <img src={SwapIcon} className="rotate-animation pointer-cursor" onClick={handleSwapValue} />
            </div>
            <div className="mt-3">
              <WithTagSelect
                label={"To"}
                placeholder={"0.0"}
                value={swapConditions.selectedTo}
                inputValue={amountOut}
                onSelectChange={(val) => setSwapConditions({ ...swapConditions, selectedTo: val })}
                optionValue={supportedTokens}
                className={`cursor_not_allowed ${Number(amountOut) < Number(swapConditions.availableTokens) ? "profit" : "loss"}`}
              />
            </div>

            <div className="mt-4">
              <p className="swap_card_details_p d-flex justify-content-between align-items-center">
                Price{" "}
                <span className="fw-600">
                  1 {swapConditions.selectedFrom?.value} = {parseFloat(swapConditions.price?.toFixed(5))}{" "}
                  {swapConditions.selectedTo?.value}
                </span>
              </p>
              <p className="swap_card_details_p d-flex justify-content-between align-items-center">
                Available Liquidity{" "}
                <span className="fw-600">
                  {parseFloat(Number(swapConditions.availableTokens).toFixed(5))} {swapConditions.selectedTo?.value}
                </span>
              </p>
              <p className="swap_card_details_p d-flex justify-content-between align-items-center">
                Swap Fee <span className="fw-600">{swapFee > 0 ? `${swapFee.toFixed(2)}%` : "-"}</span>
              </p>
              <p className="swap_card_details_p d-flex justify-content-between align-items-center">
                Minimum Received{" "}
                <span className="fw-600">
                  {minimumReceived.toString().length > 0
                    ? `${parseFloat(minimumReceived?.toFixed(5))} ${swapConditions.selectedTo?.value}`
                    : "-"}
                </span>
              </p>
              <SlippageRow setAllDetails={setSwapConditions} allDetails={swapConditions} />
            </div>
            <SwapButton />
          </Tab>
          <Tab eventKey="limit" title="LIMIT">
            <div className="mt-4">
              <FromInput
                swapConditions={swapConditions}
                setSwapConditions={setSwapConditions}
                amountIn={amountIn}
                setAmountIn={setAmountIn}
              />
              <div className="d-flex justify-content-between align-items-center mt-2">
                <span className="balance">
                  Balance : {parseFloat(swapConditions?.fromBalance?.toFixed(5))} {swapConditions.selectedFrom?.value}
                </span>
                <PercentageButtons balance={swapConditions.fromBalance} setAmountIn={setAmountIn} />
              </div>
            </div>
            <div className="d-flex justify-content-center align-items-center mt-3">
              <img src={SwapIcon} className="rotate-animation pointer-cursor" onClick={handleSwapValue} />
            </div>
            <div className="mt-3">
              <WithTagSelect
                label={"To"}
                placeholder={"0.0"}
                value={swapConditions.selectedTo}
                inputValue={amountOut}
                onSelectChange={(val) => setSwapConditions({ ...swapConditions, selectedTo: val })}
                optionValue={supportedTokens}
                className={`cursor_not_allowed ${Number(amountOut) < Number(swapConditions.availableTokens) ? "profit" : "loss"}`}
              />
            </div>

            <div className="d-flex justify-content-between align-items-center mt-4">
              <div className="w-50">
                <div className="input-wrapper referral_link_input">
                  <input value="1" className="cursor_not_allowed" />
                  <span className="me-2">{swapConditions.selectedFrom.value}</span>
                </div>
              </div>
              <div className="mx-3">
                <img src={SwapLeftRightIcon} onClick={handleSwapValue} className="rotate-animation pointer-cursor" />
              </div>
              <div>
                <div className="input-wrapper referral_link_input">
                  <input
                    type="number"
                    value={swapConditions.swapLimitPrice}
                    onChange={(e) =>
                      setSwapConditions({
                        ...swapConditions,
                        swapLimitPrice: e.target.value.length > 0 ? e.target.value : swapConditions.price,
                      })
                    }
                  />
                  <span className="me-2">{swapConditions.selectedTo.value}</span>
                </div>
              </div>
            </div>

            <div className="d-flex justify-content-end align-items-center mt-2">
              <div className="d-flex gap-2">
                <PlButton
                  onClick={() => setSwapConditions({ ...swapConditions, swapLimitPrice: swapConditions?.price })}
                  type={"percentageButton"}
                  label={"Set To Market Price"}
                />
              </div>
            </div>

            <div className="mt-4">
              <p className="swap_card_details_p d-flex justify-content-between align-items-center">
                Price{" "}
                <span className="fw-600">
                  1 {swapConditions.selectedFrom.value} = {parseFloat(Number(swapConditions.swapLimitPrice).toFixed(5))}{" "}
                  {swapConditions.selectedTo.value}
                </span>
              </p>
              <p className="swap_card_details_p d-flex justify-content-between align-items-center">
                Available Liquidity{" "}
                <span className="fw-600">
                  {parseFloat(Number(swapConditions.availableTokens).toFixed(5))} {swapConditions.selectedTo?.value}
                </span>
              </p>
              <p className="swap_card_details_p d-flex justify-content-between align-items-center">
                Swap Fee <span className="fw-600">{swapFee > 0 ? `${swapFee.toFixed(2)}%` : "-"}</span>
              </p>
              <p className="swap_card_details_p d-flex justify-content-between align-items-center">
                Minimum Received
                <span className="fw-600">
                     {minimumReceived.toString().length > 0
                       ? `${parseFloat(minimumReceived?.toFixed(5))} ${swapConditions.selectedTo?.value}`
                       : "-"}
                </span>
              </p>
            </div>
            <SwapButton />
          </Tab>
        </Tabs>
      </div>

      {swapConfirmModal && (
        <Confirm
          show={swapConfirmModal}
          onHide={() => setSwapConfirmModal(false)}
          modalDetails={{ ...swapConditions, swapFee: swapFee.toFixed(2) }}
          amountIn={amountIn}
          amountOut={amountOut}
          minimumReceived={minimumReceived}
          handleSwap={handleSwap}
        />
      )}

      {showConfirmModal && (
        <CompleteTransaction
          show={showConfirmModal}
          onHide={handleCloseSuccessModal}
          swapConditions={swapConditions}
          amountIn={amountIn}
          amountOut={amountOut}
        />
      )}
    </>
  );
};

const FromInput = ({ swapConditions, setSwapConditions, amountIn, setAmountIn }) => {
  return (
    <WithTagSelect
      label={"From"}
      placeholder={"0.0"}
      className={Number(amountIn) > swapConditions.fromBalance && "loss"}
      value={swapConditions.selectedFrom}
      inputValue={amountIn}
      onChange={(e) => setAmountIn(e.target.value)}
      onSelectChange={(val) => setSwapConditions({ ...swapConditions, selectedFrom: val })}
      optionValue={supportedTokens}
    />
  );
};

export default PlaceOrder;
