// all the business logic will go here in container
// the page will be passed with all the props needed inside
import Page from "./Page";

import { ReactComponent as TotalTrade } from "../../assets/images/totaltrading.svg";
import { ReactComponent as TotalPercentag } from "../../assets/images/totalPercentag.svg";
import { ReactComponent as TotalDolarIcon } from "../../assets/images/totaldolarIcon.svg";
import { useEffect, useState } from "react";
import { useWeb3ConnectContext } from "../../hooks/web3ConnectContext";
import { slippageOptions } from "../../components/static/SlippageOptions";
import Loader from "../../components/loader/Loader";
import { useMetaMask } from "metamask-react";
import { useCommonStateContext } from "../../hooks/commonStateContext";
import { fetchLampKavaDetails } from "../../io/kava";

const metricsData = [
  { title: "Total Deposited", value: "$28,018.42", img: <TotalDolarIcon /> },
  { title: "APR", value: "210.54%", img: <TotalPercentag /> },
  { title: "Your Deposit", value: "$0.0", img: <TotalDolarIcon /> },
];

const Container = () => {
  const { accountBalance } = useWeb3ConnectContext();
  const { account, chainId } = useMetaMask();
  const { loading, setLoading } = useCommonStateContext();

  const [kavaPoolDetails, setKavaPoolDetails] = useState({
    slippage: slippageOptions[0],
    withdrawalBalance:0
  });
  const [metricsDetails, setMetricsDetails] = useState();

  const [claimAmount, setClaimAmount] = useState();

  useEffect(() => {
    if (accountBalance !== undefined) {
      fetchLampConditions();
    }
  }, [accountBalance, account, chainId]);

  const fetchLampConditions = async () => {
    const data = await fetchLampKavaDetails(account);
    console.log('data-',data);
  };

  const handleClaim = () => {
  };
  const handleWithdraw = () => {
  };
  const handleRemoveLiquidity = () => {
  };

  return (
    <div className="swap liquidity-details-main container">
      <Loader loading={loading} />
      <Page metricsData={metricsData} kavaPoolDetails={kavaPoolDetails} setKavaPoolDetails={setKavaPoolDetails} />
    </div>
  );
};

export default Container;
