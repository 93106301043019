// Will mostly have only UI component imports and placement in this file.

import { Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import icon from "../../assets/images/liquidityIcon.svg";
import backArrow from "../../assets/images/arrow_back.svg";

import Metrics from "./cards/Metrics";
import Deposit from "./cards/Deposit";
import Withdraw from "./cards/Withdraw";
import Claim from "./cards/Claim";

const Page = ({ metricsData, kavaPoolDetails, setKavaPoolDetails }) => {
  const navigate = useNavigate();

  return (
    <>
      <h1 className="page_title dashboard_title d-flex align-items-center justify-content-start mb-0">
        <img src={icon} alt="" /> LIQUIDITY
      </h1>
      <p
        className="back_btn pointer-cursor d-flex align-items-center gap-1 w-fit-content"
        onClick={() => navigate("/liquidity")}
      >
        <img src={backArrow} width={14} height={13} /> Back
      </p>
      <Metrics metricsData={metricsData} />
      <Row xs={1} md={3} className="g-5 mt-4">
        <Deposit kavaPoolDetails={kavaPoolDetails} setKavaPoolDetails={setKavaPoolDetails} />
        <Withdraw kavaPoolDetails={kavaPoolDetails} setKavaPoolDetails={setKavaPoolDetails} />
        <Claim />
      </Row>
    </>
  );
};

export default Page;
